import React, { FC } from 'react';
import { TextField, Typography } from '@mui/material';
import { Field } from 'mobx-react-form';
import { useStyles } from './FormInputControl.styles';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  form: Field;
  field?: Field;
  inputProps?: any;
  readOnly?: boolean;
};

const FormInputControl: FC<Props> = ({ form, field, readOnly = false }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fieldClass = classNames({
    [classes.textField]: true,
    [classes.disabled]: readOnly,
  });


  return (
    <div className={classes.fieldContainer}>
      <TextField
        {...form}
        label={t(field.label)}
        className={fieldClass}
        size="small"
        InputLabelProps={{ shrink: true }}
        fullWidth
        autoComplete="off"
        inputProps={{ readOnly: readOnly }}
        placeholder={t(field.placeholder)}
      />
      {field && !readOnly && (
        <Typography mt={1} variant="body1" color="error">
          {t(field.error)}
        </Typography>
      )}
    </div>
  );
};

export default observer(FormInputControl);
