import {
  HealthScreeningModel,
  PatientSearchModel,
  WalkingAgeAboutModel,
  WalkingAgeHealthCondition,
  WalkingAgeResponseModel,
  WalkingAgeWalkingSpeed,
} from '@shared-module/Models';
import { action, makeAutoObservable } from 'mobx';

export class WalkingAgeStore {
  walkingAgeAbout: WalkingAgeAboutModel = new WalkingAgeAboutModel();
  walkingSpeed: WalkingAgeWalkingSpeed = new WalkingAgeWalkingSpeed();
  healthCondition: WalkingAgeHealthCondition = new WalkingAgeHealthCondition();
  walkingAgeDetails: WalkingAgeResponseModel = new WalkingAgeResponseModel();
  addSecondsToWalk: any;
  selectedPatient: PatientSearchModel = new PatientSearchModel();
  selfEstimatedRelativeSpeedOption: number;
  redirectUrl: string;
  diagnosisData: any = [];
  healthScreenDetails: HealthScreeningModel = new HealthScreeningModel();
  conditions: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setSelfEstimatedRelativeSpeedOption(data: number) {
    this.selfEstimatedRelativeSpeedOption = data;
  }

  @action
  setWalkingAgeAbout(data: WalkingAgeAboutModel) {
    this.walkingAgeAbout = data;
  }

  @action
  setHealthScreenDetails(data: HealthScreeningModel) {
    this.healthScreenDetails = data;
  }

  @action
  setWalkingSpeed(data: WalkingAgeWalkingSpeed) {
    this.walkingSpeed = data;
  }

  @action
  setHealthCondition(data: WalkingAgeHealthCondition) {
    this.healthCondition = data;
  }

  @action
  setAddSecondsToWalk(data: any) {
    this.addSecondsToWalk = data;
  }

  @action
  setWalkingAgeDetails(data: WalkingAgeResponseModel) {
    this.walkingAgeDetails = data;
  }

  @action
  setSelectedPatient(data: PatientSearchModel) {
    this.selectedPatient = data;
  }

  @action
  reset() {
    this.selectedPatient = new PatientSearchModel();
  }

  @action
  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  @action
  setDiagnosisData(data: any) {
    this.diagnosisData = data;
  }

  @action
  setConditionArray(data: any) {
    this.conditions = data;
  }
}
