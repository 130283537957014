import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  calendarHeaderContainer: {
    backgroundColor: '#1e4fa1',
    width: '100%',
    margin: 0,
    padding: '10px',
    display: 'table',
    borderRadius: '3px 3px 0 0',
    '& span': {
      cursor: 'pointer',
      color: '#fff',
      margin: 0,
      padding: 0,
      display: 'table-cell',
      fontSize: '16px',
      textAlign: 'center',
      textShadow: '-1px -1px 0 rgba(0, 0, 0, 0.15)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      position: 'relative',
    },
  },
}));
