import React, { FC } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import { useStyles } from './FormInputSwitchControl.styles';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  switchHandler: (event) => void;
};

const FormSwitchControl: FC<Props> = (props: Props) => {
  const { label, switchHandler } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch className={classes.switch} />}
        label=  {t(label)}
        onChange={e => switchHandler(e)}
      />
    </FormGroup>
  );
};

export default observer(FormSwitchControl);
