import { createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  dialogContainer: {
    padding: theme.spacing(2),
    '& .MuiPaper-root': {
      width: '500px',
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    '&.MuiTypography-root': {
      padding: theme.spacing(1, 0),
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
    '& .MuiButton-root': {
      backgroundColor: '#1e4fa1',
      textTransform: 'capitalize',
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
}));
