import { ITmepDayofWeeks } from '@shared-module/Interfaces';
import { CategoryModel } from '.';

export class TmepDayOfWeeksModel {
  id: number;
  dayofWeekId: number;
  multimodalExerciseProgramId: number;
  dayofWeek: CategoryModel;
  exerciseStartDate: string;
  weekId: number;

  constructor(data?: Partial<TmepDayOfWeeksModel>) {
    Object.assign(this, data)
  }

  static deserialize(apiData: ITmepDayofWeeks): TmepDayOfWeeksModel {
    if (!apiData) {
      return new TmepDayOfWeeksModel();
    }

    const data: Partial<TmepDayOfWeeksModel> = {
      id: apiData.id,
      dayofWeekId: apiData.dayofWeekId,
      multimodalExerciseProgramId: apiData.multimodalExerciseProgramId,
      dayofWeek: CategoryModel.deserialize(apiData.dayofWeek),
      exerciseStartDate: apiData.exerciseStartDate,
      weekId: apiData.weekId,
    };

    return new TmepDayOfWeeksModel(data);
  }

  static deserializeList(apiDataList: ITmepDayofWeeks[]): TmepDayOfWeeksModel[] {
    return apiDataList
      ? apiDataList.map((apiData: ITmepDayofWeeks) =>
        new TmepDayOfWeeksModel(TmepDayOfWeeksModel.deserialize(apiData)))
      : [];
  }
}