import { AdminProfileModel, HCPProfile, PatientSearchModel } from '@shared-module/Models';
import { action, makeAutoObservable } from 'mobx';

export class AdminStore {
  adminProfile: AdminProfileModel = new AdminProfileModel();
  hcpDetails: HCPProfile[] = [];
  selectedHCP: HCPProfile = new HCPProfile()
  selectedPatient: PatientSearchModel = new PatientSearchModel()
  allPatients: PatientSearchModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setAdminDetails(data: AdminProfileModel) {
    this.adminProfile = data;
  }

  @action
  setHcpData(data: HCPProfile[]) {
    this.hcpDetails = data;
  }

  @action
  setSelectedHCP(data: HCPProfile) {
    this.selectedHCP = data;
  }

  @action
  setSelectedPatient(data: PatientSearchModel) {
    this.selectedPatient = data;
  }

  @action
  setPatients(data: PatientSearchModel[]) {
    this.allPatients = data;
  }

  
}
