import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

type Props = {
  label: string;
  value: number;
  name: string;
  dataList: number[];
  isReadOnly?: boolean;
  isDisabled?: boolean;
  onChange: (e: SelectChangeEvent<number>) => void;
};

const CustomSelect: FC<Props> = ({
  label,
  value,
  name,
  dataList,
  isReadOnly = false,
  isDisabled = false,
  onChange,
}: Props) => {
  return (
    <FormControl sx={{ mr: 1, mb: 1, minWidth: 80 }} size="small">
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        readOnly={isReadOnly}
        value={value}
        name={name}
        label={label}
        disabled={isDisabled}
        onChange={e => onChange(e)}
      >
        {dataList.map(count => (
          <MenuItem key={count} value={count}>
            {count}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
