import moment from 'moment';

export function daysInMonth(date) {
  return date.daysInMonth();
}

export function firstDayOfMonth(date): number {
  const firstDay = moment(date).startOf('month').format('d');
  return Number(firstDay);
}

export function getDay(startDate: string): number {
  const date = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
  return Number(date.format('D'));
}

export function getDates(startDate, stopDate) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const endDate = moment(stopDate);
  while (currentDate <= endDate) {
    dateArray.push(moment(currentDate).format('YYYY'));
    currentDate = moment(currentDate).add(1, 'year');
  }
  return dateArray;
}
