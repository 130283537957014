export class WalkingAge {
  patientId: number;
  selfEstimatedRelativeSpeedOption: number;
  existingTestWalkingSpeedValue: number;
  dateOfBirth: string;
  isFemale: boolean;
  weight: number;
  height: number;
  addSecondsToWalk: number;
  backPain: boolean;
  legsPain: boolean;
  fallWithinLast2Years: boolean;
  fivePrescribedMedications: boolean;
  hospitalStay: boolean;
  jointReplacementOperation: boolean;
  hadStroke: boolean;
  lowerLimbOsteoarthritis: boolean;
  diabetes: boolean;
  cognitiveImpairment: boolean;
  depression: boolean;
  multipleSclerosis: boolean;
  parkinsons: boolean;
  depressionScore: number = 0;
  cognitiveImpairmentScore: number = 0;
  osteoarthritistScore: number = 0;
  diabetesScore: number = 0;




  constructor(data?: Partial<WalkingAge>) {
    Object.assign(this, data);
  }
}
