import React, { FC, PropsWithChildren } from 'react';
import { observer,inject } from 'mobx-react';
import { IconButton, Avatar, CssBaseline, Box, Toolbar, Button, Link, AppBar } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { DoctorImage, Logo } from '@assets';
import { AdminMenuList, HCPMenuList, PatientMenuList } from '@utilities';
import { AuthStore, ProgressStore, RootStore } from '@shared-module/Stores';
import SidebarList from '../SidebarList/SidebarList';
import { styles } from './Layout.style';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router';
import { IClasses } from '@shared-module/Interfaces';
import { withStyles } from '@mui/styles';
import LanguageSelect from 'src/Pages/LandingPage/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


type Props = {
  children?: PropsWithChildren<{}>;
  browserWindow?: () => Window;
  classes?: IClasses;
  progressStore?: ProgressStore;
};
const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    background: '#1e4fa1',
    padding: theme.spacing(0, 2),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Layout: FC<Props> = ({ children, browserWindow, classes,progressStore }: Props) => {
  const [ open, setOpen ] = React.useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const hcpInvite: boolean = location.pathname && location.pathname.split('/').some(x => x == 'invite');
  const registration: boolean = location.pathname && location.pathname.split('/').some(x => x == 'registration');
  const hcpRegistration: boolean = location.pathname && location.pathname.split('/').some(x => x == 'register-hcp');
 

  const [ mobileOpen, setMobileOpen ] = React.useState(false);

  const container = browserWindow !== undefined ? () => browserWindow().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogout = () => {
    localStorage.clear();
    RootStore.resetApp();
    AuthStore.logOut();
    progressStore.setWeeklyProgress([])
    navigate('/');

  };

  const getMenuList = () => {
    if (AuthStore.appUser?.isAdmin) {
      return AdminMenuList;
    }
    return AuthStore.appUser?.isHcpUser ? HCPMenuList : PatientMenuList;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ display: { md: 'none' } }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <MuiDrawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#1e4fa1',
            padding: '10px 0px 0px',
          },
        }}
      >
        <div onClick={handleDrawerToggle}>
          <SidebarList menuList={getMenuList()} />
        </div>
        <div className={classes.footer}>
          <div className={classes.logoutWrapper}>
            <Link onClick={onLogout}>
              <Avatar alt="Doctor image" src={DoctorImage} />
            </Link>
            {mobileOpen && (
              <Button variant="text" className={classes.logout} onClick={onLogout}>
                {t('Logout')}
              </Button>
            )}
          </div>
        </div>
      </MuiDrawer>
      <Drawer variant="permanent" open={!open} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Toolbar className={classes.toolbarIcon}>
          {!open ? (
            <IconButton onClick={handleDrawerOpen}>
              <ChevronLeftIcon className={classes.icon} />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerClose}>
              <Logo width={40} height={40} className={classes.logo} />
            </IconButton>
          )}
        </Toolbar>
        <SidebarList menuList={getMenuList()} />
        <div className={classes.footer}>
          <div className={classes.logoutWrapper}>
            <Link onClick={onLogout}>
              <Avatar alt="Doctor image" src={DoctorImage} />
            </Link>
            {!open && (
              <Button variant="text" className={classes.logout} onClick={onLogout}>
                {t('Logout')}
              </Button>
            )}
          </div>
        </div>
      </Drawer>
      <Box component="main" className={classes.content}>
        {!hcpInvite && !registration && !hcpRegistration  && (
          <LanguageSelect classes={{ flagContainer: classes.languageContainer }} />
        )}
        {children}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(inject('progressStore')(observer(Layout)));