import { HCPProfile } from '@shared-module/Models';
import { MEPModel } from '@shared-module/Models/MEP.model';
import { action, makeAutoObservable } from 'mobx';

export class HCPDashboardStore {
  hcpProfile: HCPProfile = new HCPProfile(); 
  tmepDetails: MEPModel[] = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setHCPProfileData(data: HCPProfile) {
    this.hcpProfile = data;
  }

  @action
  public setTmepDetails(data: MEPModel[]) {
    this.tmepDetails = data;
  }
}
