import { IPatient } from '@shared-module/Interfaces';
import { formattedDate } from '@utilities';

export class PatientModel {
  id: number;
  allergies: string;
  diseases: string;
  diseasesInfo: string;
  height: number;
  weight: number;
  lastVisit: string;
  nextVisit: string;

  constructor(data?: Partial<PatientModel>) {
    Object.assign(this, data);
  }

  get patientHeight() {
    return this.height > 0 ? this.height : 'N/A';
  }

  get patientWeight() {
    return this.weight > 0 ? this.weight : 'N/A';
  }

  get lastVisitFormattedDate() {
    return formattedDate(this.lastVisit);
  }

  get nextVisitFormattedDate() {
    return formattedDate(this.nextVisit);
  }

  static deserialize(apiData: IPatient): PatientModel {
    if (!apiData) {
      return new PatientModel();
    }

    const data: Partial<PatientModel> = {
      id: apiData.id,
      allergies: apiData.allergies,
      diseases: apiData.diseases,
      diseasesInfo: apiData.diseasesInfo,
      height: apiData.height,
      weight: apiData.weight,
      lastVisit: apiData.lastVisit,
      nextVisit: apiData.nextVisit,
    };

    return new PatientModel(data);
  }

  static deserializeList(apiDataList: IPatient[]): PatientModel[] {
    return apiDataList
      ? apiDataList.map((apiData: IPatient) => new PatientModel(PatientModel.deserialize(apiData)))
      : [];
  }
}
