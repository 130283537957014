import {
  PatientDailyReportModel, PatientSearchModel, PatientTMEPModel,
  TekuTekuModel, WeeklyTekuTekuModel,
} from '@shared-module/Models';
import { action, makeAutoObservable } from 'mobx';

export class PatientStore {
  patientDetails: PatientSearchModel = new PatientSearchModel();
  patientTMEP: PatientTMEPModel = null;
  tekuDetails: TekuTekuModel = new TekuTekuModel();
  patientReports: PatientDailyReportModel[] = [];
  tekoTekoPoints: number;
  selectedtekuDetails: TekuTekuModel = new TekuTekuModel();
  programStartDate: string = '';
  tekutekuData: WeeklyTekuTekuModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setPatientDetails(data: PatientSearchModel) {
    this.patientDetails = data;
  }

  @action
  setPatientTMEP(data: PatientTMEPModel) {
    this.patientTMEP = data;
  }

  @action
  setPatientReports(data: PatientDailyReportModel[]) {
    this.patientDetails.patientReport = this.patientReports = data;
  }

  @action
  setTekuTekuPoints(data: number) {
    this.tekoTekoPoints = data;
  }

  @action
  setTekuDetails(data: TekuTekuModel) {
    this.tekuDetails = data;
  }

  @action
  setSelectedTekuDetails(data: TekuTekuModel) {
    this.selectedtekuDetails = data;
  }

  @action
  setProgramStartDate(date: string) {
    this.programStartDate = date;
  }

  @action
  setTekuTekuData(data: WeeklyTekuTekuModel[]) {
    this.tekutekuData = data;
  }
}
