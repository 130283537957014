import { IProgramByLevel } from '@shared-module/Interfaces';
import { MEPExerciseCategory } from '.';

export class ProgramByLevel {
  id: number;
  mepExerciseCategories: MEPExerciseCategory[];

  constructor(data?: Partial<ProgramByLevel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IProgramByLevel): ProgramByLevel {
    if (!apiData) {
      return new ProgramByLevel();
    }

    const data: Partial<ProgramByLevel> = {
      id: apiData.id,
      mepExerciseCategories: MEPExerciseCategory.deserializeList(apiData.mepExerciseCategories),
    };

    return new ProgramByLevel(data);
  }

  static deserializeList(apiDataList: IProgramByLevel[]): ProgramByLevel[] {
    return apiDataList
      ? apiDataList.map((apiData: IProgramByLevel) => new ProgramByLevel(ProgramByLevel.deserialize(apiData)))
      : [];
  }
}
