import { HCPDashboardStore } from './HCPDashboard.store';
import { HCPPatientStore } from './HCPPatient.store';
import { PatientStore } from './Patient.store';
import { ProgramStore } from './Program.store';
import { WalkingAgeStore } from './WalkingAge.store';
import { InviteStore } from './Invite.store';
import { ProgressStore } from './Progress.store';
import { AdminStore } from './Admin.store';

class RootStore {
  public hcpDashboardStore: HCPDashboardStore;
  public hcpPatientStore: HCPPatientStore;
  public programStore: ProgramStore;
  public patientStore: PatientStore;
  public walkingAgeStore: WalkingAgeStore;
  public inviteStore: InviteStore;
  public progressStore: ProgressStore;
  public adminStore: AdminStore;

  constructor() {
    this.hcpDashboardStore = new HCPDashboardStore();
    this.hcpPatientStore = new HCPPatientStore();
    this.programStore = new ProgramStore();
    this.patientStore = new PatientStore();
    this.walkingAgeStore = new WalkingAgeStore();
    this.inviteStore = new InviteStore();
    this.progressStore = new ProgressStore();
    this.adminStore = new AdminStore();
  }

  public resetApp(): void {
    // this.hcpDashboardStore.reset();
    // this.hcpPatientStore.reset();
    // this.programStore.reset();
    this.walkingAgeStore.reset();
  }
}
const rootStore = new RootStore();

export default rootStore;
