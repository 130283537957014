import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        // height: '100%',
      },
      backgroundColor: '#1e4fa1',
    },
    flagContainer: {
      height: '50px',
      width: '100%',
      textAlign: 'right',
      paddingTop: '5px',
      paddingRight: '10px',
      '& img': {
        maxWidth: '100%',
        height: '25px',
        width: '40px',
        objectFit: 'cover',
      },
    },
    imageContainer: {
      textAlign: 'center',
      height: '93%',
      display: 'flex',
      '&.MuiGrid-root': {
        overflow: 'auto',
        flexWrap: 'nowrap',
      },
      [theme.breakpoints.down('sm')]:{
        height: '94pa%',
      },
    },
    logoImage: {
      margin: theme.spacing(1.25, 0),
    },
    title: {
      fontWeight: 600,
      color: theme.palette.common.white,
      margin: '20px 0',
    },
    description: {
      padding: '0 60px',
      lineHeight: '30px',
      margin: '0 0 30px 0',
      color: theme.palette.common.white,
    },
    pageButton: {
      '&.MuiButton-root': {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
        color: '#3a6293',
        borderRadius: 40,
        margin: '12px',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 18,
      },
      width: 280,
      [theme.breakpoints.down('sm')]: {
        width: 220,
      },
    },
    navLink: {
      textDecoration: 'none',
    },
    loginLink: {
      position: 'absolute',
      right: '20px',
      top: '20px',
      color: theme.palette.common.white,
    },
    enabled: {
      '&.MuiButton-root': {
        background: theme.palette.grey[500],
        '&:hover': {
          background: theme.palette.grey[500],
        },
      },
    },
  });
