import React from 'react';
import { useStyles } from './CalendarHeader.styles';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTranslation } from 'react-i18next';

const CalendarHeader = props => {
  const { onPrev, showMonth, month, year, onNext, showYearTable, state } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  return (
    <div className={classes.calendarHeaderContainer}>
      <span
        onClick={e => {
          onPrev();
        }}
      >
        <ArrowLeftIcon />
      </span>
      {!state.showMonthTable && (
        <span
          onClick={e => {
            showMonth();
          }}
        >
          {t(month())}
        </span>
      )}
      <span onClick={e => showYearTable(e)}>{year()}</span>
      <span
        onClick={e => {
          onNext();
        }}
      >
        <ArrowRightIcon />
      </span>
    </div>
  );
};

export default CalendarHeader;
