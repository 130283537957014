import React, { FC, ReactNode } from 'react';
import moment from 'moment';
import { getDates } from '@shared-module/Utilities/CalendarUtils';
import { useTranslation } from 'react-i18next';

type Props = {
  setYear: (year: number) => void;
  year: any;
};

const CalendarYearTable: FC<Props> = (props: Props) => {
  const months = [];
  const { year, setYear } = props;
  const { t } = useTranslation();

  const nextTenYears = moment().set('year', Number(year)).add('year', 12).format('Y');

  const renderTenYears = () => {
    const tenYears = getDates(year, nextTenYears);
    tenYears.map(data => {
 
      return months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            setYear(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
  };

  const renderYearList = (): ReactNode => {
    renderTenYears();
    const rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    return rows.map((years, index) => {
      
      return <tr key={index}>{years}</tr>;
    });
  };

  return (
    <table className="calendar-month">
      <thead>
        <tr>
          <th colSpan={4}>{t('Select a Year')}</th>
        </tr>
      </thead>
      <tbody>{renderYearList()}</tbody>
    </table>
  );
};

export default CalendarYearTable;
