import { IAdminProfile } from '@shared-module/Interfaces';

export class AdminProfileModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  hcpCount: number;

  constructor(data?: Partial<AdminProfileModel>) {
    Object.assign(this, data);
  }

  get fullName() {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : '';
  }

  static deserialize(apiData: IAdminProfile): AdminProfileModel {
    if (!apiData) {
      return new AdminProfileModel();
    }

    const data: Partial<AdminProfileModel> = {
      ...apiData,
    };

    return new AdminProfileModel(data);
  }


}
