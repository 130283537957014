import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  calendarContainer: {
    width: 400,
    height: 'auto',
    padding: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3125)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  calendarTableContainer: {
    margin: 0,
    padding: 0,
    display: 'block',
    '& table': {
      width: '100%',
      margin: 0,
      padding: 0,
      borderSpacing: 0,
      borderCollapse: 'collapse',
      '& thead tr > *, & tbody tr > *': {
        color: '#1e4fa1',
        width: '44px',
        height: '44px',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: 'normal',
        lineHeight: '35px',
        border: 0,
        backgroundColor: '#ffffff',
        padding: '5px 2px',
      },
      '& tbody tr > *': {
        color: '#334455',
        cursor: 'pointer',
        position: 'relative',
        fontSize: 16,
        backgroundColor: '#ffffff',
      },
      '& tbody tr > td.disabled': {
        color: '#c3c3c3',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
      '& tbody tr > * > span': {
        color: '#1e4fa1',
        zIndex: 10,
        position: 'relative',
      },
      '& tbody tr > *::after': {
        top: '3px',
        left: '3px',
        width: '29px',
        height: '29px',
        content: '',
        display: 'block',
        position: 'absolute',
        borderRadius: '50%',
      },
      '& tbody tr > *.today': {
        color: '#1e4fa1',
        '& span': {
          background: '#b0c1dc',
          borderRadius: '50%',
        },
        '& div': {
          color: '#1e4fa1',
          fontWeight: 800,
        },
      },
      '& tbody tr > .active-week': {
        backgroundColor: '#add8e680',
      },
      '& tbody tr > *.empty, & tbody tr > *.disable': {
        color: '#8899aa',
        cursor: 'not-allowed',
        backgroundColor: '#efefef',
      },
      '& tbody tr > *.empty:hover:after, & tbody tr > *.disable:hover:after': {
        content: '',
        display: 'none',
      },
      '& tbody tr > *.current span': {
        color: '#fff',
      },
      '& tbody tr > *.current:after': {
        borderColor: '#3296c8',
        backgroundColor: '#3296c8',
      },
      '&.calendar-month tbody tr > *': {
        width: 80,
        padding: '5px',
        lineHeight: '25px',
      },
      '&.calendar-month tbody tr > * span': {
        display: 'block',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderRadius: '3px',
        WebkitBorderRadius: '3px',
      },
      '&.calendar-month tbody tr > *:hover span': {
        borderColor: '#d0d0d0',
        boxShadow: '0 1px 0 0 #efefef',
        WebkitBoxShadow: '0 1px 0 0 #efefef',
      },
    },
  },
}));
