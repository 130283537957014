import React, { LazyExoticComponent } from 'react';

interface IModule {
  lazyComponent: LazyExoticComponent<any>;
  path: string;
  isPrivate?: boolean;
  permission?: string;
}

export const routes: IModule[] = [
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "landing-page" */ './Pages/LandingPage/LandingPage')),
    path: '/',
  },
  {
    lazyComponent: React.lazy(
      () => import(/* webpackChunkName: "authentication" */ './Pages/Auth/MainLogin/MainLogin')
    ),
    path: 'login',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "authentication" */ './Pages/Auth/Login/Login')),
    path: 'login-admin',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "authentication" */ './Pages/Auth/Login/Login')),
    path: 'login-hcp',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "authentication" */ './Pages/Auth/Login/Login')),
    path: 'login-patient',
  },
  {
    lazyComponent: React.lazy(
      () => import(/* webpackChunkName: "authentication" */ './Pages/Auth/PatientRegistration/PatientRegistration')
    ),
    path: 'registration',
  },
  {
    lazyComponent: React.lazy(
      () => import(/* webpackChunkName: "authentication" */ './Pages/Auth/RegistrationInvited/RegistrationInvited')
    ),
    path: 'registration/:id',
  },
  {
    lazyComponent: React.lazy(
      () => import(/* webpackChunkName: "authentication" */ './Pages/Auth/ResetPassword/ResetPassword')
    ),
    path: 'reset-password',
  },
  {
    lazyComponent: React.lazy(
      () => import(/* webpackChunkName: "authentication" */ './Pages/Auth/PasswordConfirmation/PasswordConfirmation')
    ),
    path: 'reset-password/:id',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "admin" */ './Pages/Admin/AdminRoutes')),
    path: 'admin',
    isPrivate: true,
    permission: 'isAdmin',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "hcp" */ './Pages/HCP/HCPRoutes')),
    path: 'hcp',
    isPrivate: true,
    permission: 'hcpId',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "patient" */ './Pages/Patient/PatientRoutes')),
    path: 'patient',
    isPrivate: true,
    permission: 'patientId',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "walking-age" */ './Pages/PublicRoutes/PublicRoutes')),
    path: 'walking-age',
  },
  {
    lazyComponent: React.lazy(() => import(/* webpackChunkName: "health-conditions" */ './Pages/GaitSpeed/GaitSpeed')),
    path: 'gait-speed',
  },
  {
    lazyComponent: React.lazy(
      () => import(/* webpackChunkName: "health-conditions" */ './Pages/HealthConditions/HealthConditions')
    ),
    path: 'health-conditions',
  },
  {
    lazyComponent: React.lazy(
      () => import(/* webpackChunkName: "health-conditions" */ './Pages/ThanksPage/ThanksPage')
    ),
    path: 'inquiry',
  },
];
