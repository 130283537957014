import { UserRoleName } from '@shared-module/Enums';
import { IUserResponse } from '@shared-module/Interfaces';
import { getDecodedAuthToken } from '@utilities';
import { CategoryModel } from '.';

export class UserResponse {
  email: string;
  firstName: string;
  lastName: string;
  userRole: CategoryModel;
  userToken: string;
  hcpId: number;
  patientId: number;
  userId: number;
  isAdmin: boolean;
  exp?: number;
  iat?: number;
  nbf?: number;

  constructor(data?: Partial<UserResponse>) {
    Object.assign(this, data);
  }

  public get fullName(): string {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : '';
  }

  public get isHcpUser(): boolean {
    return this.userRole.name === UserRoleName.HCP;
  }

  public get loginUserId(): number {
    if (this.isAdmin) return this.userId;
    return this.isHcpUser ? this.hcpId : this.patientId;
  }

  static deserialize(apiData: IUserResponse): UserResponse {
    if (!apiData) {
      return new UserResponse();
    }

    const token = getDecodedAuthToken(apiData.userToken);

    const data: Partial<UserResponse> = {
      email: apiData.email,
      firstName: apiData.firstName,
      lastName: apiData.lastName,
      hcpId: apiData.hcpId,
      userId: apiData.userId,
      patientId: apiData.patientId,
      isAdmin: apiData.isAdmin,
      userRole: CategoryModel.deserialize(apiData.userRole),
      userToken: apiData.userToken,
      exp: token?.exp,
      iat: token?.iat,
      nbf: token?.nbf,
    };

    return new UserResponse(data);
  }
}
