import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  mainHeading: {
    '&.MuiTypography-h6': {
      color: '#721c24',
      backgroundColor: '#f8d7da',
      borderColor: '#f5c6cb',
    },
  },
  content: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& .MuiDialogContentText-root': {
      paddingTop: '20px',
      color:theme.palette.common.black ,
    },
  },
  submit: {
    '&.MuiButton-root': {
      textTransform: 'none',
      background: '#1e4fa1',
    },
  },
}));
