import React, { FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import AuthErrorStore from './AuthError.store';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import { AuthStore, RootStore } from '@shared-module/Stores';
import { DialogTitle } from '@mui/material';
import { useStyles } from './AuthErrorContainer.styles';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AuthErrorContainer: FC = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ open, setOpen ] = React.useState(false);
  const classes = useStyles();
  useEffect(() => {
    if(AuthErrorStore.hasError===true){
      setOpen(true);
      
    }
    
  }, [ AuthErrorStore.hasError ]);

  const handleClose = () => {
    setOpen(false);
    // navigate('/login');
    // localStorage.removeItem('Usertoken');
    localStorage.clear();
    RootStore.resetApp();
    AuthStore.logOut();
    navigate('/login');
  };
  return AuthErrorStore.hasError ? (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={classes.mainHeading}>{t('Session timeout')}!</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-slide-description">
            {t('Session is expired. Please login again to continue')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" className={classes.submit}>
            {t('Login')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <>{children}</>
  );
};

export default AuthErrorContainer;
