import React, { FC, ReactNode, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type Props = {
  open: boolean;
  onClose: (isClosed: boolean) => void;
  title?: string;
  dialogContent?: ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
  isRestrictClose?: boolean;
};

const CustomModal: FC<Props> = ({
  open,
  onClose,
  dialogContent,
  maxWidth,
  fullWidth,
  isRestrictClose = false,
}: Props) => {
  const [ isOpen, setOpen ] = React.useState(false);

  useEffect(() => {
    setOpen(open);
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  return (
    <BootstrapDialog
      onClose={() => !isRestrictClose ? handleClose() : null}
      open={isOpen}
      maxWidth={maxWidth || 'sm'}
      fullWidth={fullWidth || false}
    >
      <BootstrapDialogTitle onClose={handleClose} />
      {dialogContent && dialogContent}
    </BootstrapDialog>
  );
};

export default CustomModal;
