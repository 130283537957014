import { IWeeklyProgress } from '@shared-module/Interfaces';

export class WeeklyProgressModel {
  id: number;
  patientId: number;
  currentScore: number;
  targetScore: number;
  week: number;

  constructor(data?: Partial<WeeklyProgressModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IWeeklyProgress): WeeklyProgressModel {
    if (!apiData) {
      return new WeeklyProgressModel();
    }

    const data: Partial<WeeklyProgressModel> = {
      id: apiData.id,
      patientId: apiData.patientId,
      currentScore: apiData.currentScore,
      targetScore: apiData.targetScore,
      week: apiData.week,
    };

    return new WeeklyProgressModel(data);
  }

  static deserializeList(apiDataList: IWeeklyProgress[]): WeeklyProgressModel[] {
    return apiDataList
      ? apiDataList.map((apiData: IWeeklyProgress) => new WeeklyProgressModel(WeeklyProgressModel.deserialize(apiData)))
      : [];
  }
}
