export enum Level {
  BEGINNER = 'Beginner',
  POOR = 'Poor',
  BELOWNORMAL = 'Below Normal',
  NORMAL = 'Normal',
  ABOVENORMAL = 'Above Normal',
  ADVANCED = 'Advanced',
  EXPERT = 'Expert',
}

export enum Level_IDS {
  BEGINNER = 1,
  POOR,
  BELOWNORMAL,
  NORMAL,
  ABOVENORMAL,
  ADVANCED,
  EXPERT,
}
