import React, { FC, useState } from 'react';
import moment from 'moment';
import { useStyles } from './CustomCalendar.styles';
import { CircularProgress } from '@mui/material';
import { DaywiseTekuScoresModel } from '@shared-module/Models';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import { daysInMonth, firstDayOfMonth, getDay } from '@shared-module/Utilities/CalendarUtils';
import CalendarYearTable from './CalendarYearTable/CalendarYearTable';
import CalendarMonthTable from './CalendarMonthTable/CalendarMonthTable';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedData: (date: string) => void;
  dayWiseTekuTekuScores: DaywiseTekuScoresModel[];
  exerciseStartDate: string;
  currentWeek: string[];
};

const CustomCalendar: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const weekdayshort = moment.weekdaysShort();
  const [ state, setState ] = useState({
    showYearTable: false,
    showMonthTable: false,
    showDateTable: true,
    dateObject: moment(),
    allmonths: moment.months(),
    selectedDay: null,
    fullDate: null,
    weekId: null,
  });

  const year = () => {
    return state.dateObject.format('Y');
  };

  const month = () => {
    return state.dateObject.format('MMMM');
  };
 
  const showMonth = () => {
    setState({
      ...state,
      showMonthTable: !state.showMonthTable,
      showDateTable: !state.showDateTable,
    });
  };

  const setMonth = month => {
    const monthNo = state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, state.dateObject);
    dateObject = moment(dateObject).set('month', monthNo);
    setState({
      ...state,
      dateObject: dateObject,
      showMonthTable: !state.showMonthTable,
      showDateTable: !state.showDateTable,
    });
  };

  const showYearTable = e => {
    setState({
      ...state,
      showYearTable: !state.showYearTable,
      showDateTable: !state.showDateTable,
    });
  };

  const onPrev = () => {
    let curr: moment.unitOfTime.Base = 'month';
    if (state.showYearTable === true) {
      curr = 'year';
    }
    setState({
      ...state,
      dateObject: state.dateObject.subtract(1, curr),
    });
  };

  const onNext = () => {
    let curr: moment.unitOfTime.Base = 'month';
    if (state.showYearTable === true) {
      curr = 'year';
    }
    setState({
      ...state,
      dateObject: state.dateObject.add(1, curr),
    });
  };

  const setYear = (year: number) => {
    let dateObject = Object.assign({}, state.dateObject);
    dateObject = moment(dateObject).set('year', year);
    setState({
      ...state,
      dateObject: dateObject,
      showMonthTable: !state.showMonthTable,
      showYearTable: !state.showYearTable,
    });
  };

  const onDayClick = (e, d) => {
    const fullDate = `${d}-${month()}-${year()}`;
    const formattedFullDate = moment(fullDate).format('YYYY-MM-DD');
    props.selectedData(formattedFullDate);
    setState({ ...state, selectedDay: d, fullDate: formattedFullDate });
  };

  const weekdayshortname = weekdayshort.map((day, i) => {
  
    return <th key={i}>{t(day)}</th>;
  });

  const blanks = [];

  for (let i = 0; i < firstDayOfMonth(state.dateObject); i++) {
    blanks.push(<td className="calendar-day empty">{''}</td>);
  }

  const daysInMonthData = [];

  for (let d = 1; d <= daysInMonth(state.dateObject); d++) {
    const data = props.dayWiseTekuTekuScores?.find(x => d == getDay(x.exerciseStartDate));

    const selectedDay = state.selectedDay === d ? 'today' : '';

    let currentDay;
    if (data) {
      currentDay = moment(data.exerciseStartDate).format('YYYY-MM') == moment(state.dateObject).format('YYYY-MM');
      console.log('Date', state.selectedDay);
    }

    const obj = {
      day: d,
      progressValue: data?.excerciseCompletedPercentage ?? 0,
    };

    const checkDate = (d: number) => {
      const fullDate = `${d}-${month()}-${year()}`;
      return moment(fullDate).isBefore(moment(props.exerciseStartDate).format('YYYY-MM-DD')) ? 'disabled' : '';
    };

    const fullDate = `${d}-${month()}-${year()}`;
    const formattedFullDate = moment(fullDate).format('YYYY-MM-DD');
    const currentWeekDates = props.currentWeek?.some(x => x == formattedFullDate);

    daysInMonthData.push(
      <td
        key={obj.day}
        className={`calendar-day ${selectedDay} ${currentWeekDates ? 'active-week' : ''} ${checkDate(obj.day)}`}
        onClick={e => {
          onDayClick(e, d);
        }}
      >
        {currentDay && (
          <CircularProgress
            style={{ position: 'relative' }}
            variant="determinate"
            value={obj.progressValue}
            thickness={5}
            size={40}
          />
        )}
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '10',
          }}
        >
          {d}
        </div>
      </td>
    );
  }

  const totalSlots = [ ...blanks, ...daysInMonthData ];
  const rows = [];
  let cells = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      rows.push(cells);
      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      rows.push(cells);
    }
  });

  const renderDaysInMonth = rows.map((d, i) => {
    
    return <tr key={i}>{d}</tr>;
  });

  return (
    <div className={classes.calendarContainer}>
      <CalendarHeader
        onPrev={onPrev}
        showMonth={showMonth}
        month={month}
        year={year}
        onNext={onNext}
        showYearTable={showYearTable}
        state={state}
      />
      <div className={classes.calendarTableContainer}>
        {state.showYearTable && <CalendarYearTable setYear={(year: number) => setYear(year)} year={year()} />}
        {state.showMonthTable && (
          <CalendarMonthTable setMonth={month => setMonth(month)} monthsData={moment.months()} />
        )}
      </div>

      {state.showDateTable && (
        <div className={classes.calendarTableContainer}>
          <table>
            <thead>
              <tr>{weekdayshortname}</tr>
            </thead>
            <tbody>{renderDaysInMonth}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CustomCalendar;
