import { PatientSearchModel, UserResponse } from '@shared-module/Models';
import { action, makeAutoObservable } from 'mobx';

export class InviteStore {
  invitedPatient: PatientSearchModel = new PatientSearchModel();
  userDetail: UserResponse = new UserResponse();

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setInvitedPatient(data: PatientSearchModel) {
    this.invitedPatient = data;
  }

  @action
  setUserDetail(data: UserResponse) {
    this.userDetail = data;
  }
}
