import { ITekuTekuScore } from '@shared-module/Interfaces';
import { DaywiseTekuScoresModel } from './TekuTeku.model';

export class WeeklyTekuTekuModel {
  weeklyTarget: number = 0;
  weekId: number;
  weeklyAchievedTekuTeku: number = 0;
  dayWiseTekuTekuScores: DaywiseTekuScoresModel[];

  constructor(data?: Partial<WeeklyTekuTekuModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ITekuTekuScore): WeeklyTekuTekuModel {
    if (!apiData) {
      return new WeeklyTekuTekuModel();
    }

    const data: Partial<WeeklyTekuTekuModel> = {
      weeklyTarget: apiData.weeklyTarget,
      weekId: apiData.weekId,
      weeklyAchievedTekuTeku: apiData.weeklyAchievedTekuTeku,
      dayWiseTekuTekuScores: DaywiseTekuScoresModel.deserializeList(apiData.dayWiseTekuTekuScores),
    };

    return new WeeklyTekuTekuModel(data);
  }

  static deserializeList(apiDataList: ITekuTekuScore[]): WeeklyTekuTekuModel[] {
    return apiDataList
      ? apiDataList.map((apiData: ITekuTekuScore) => new WeeklyTekuTekuModel(WeeklyTekuTekuModel.deserialize(apiData)))
      : [];
  }
}

