import { Navigate } from 'react-router-dom';
import { AuthStore } from '@shared-module/Stores';
import React, { FC, LazyExoticComponent } from 'react';

interface Props {
  isPrivate?: boolean;
  permission?: string;
  element: LazyExoticComponent<any>;
}

const PrivateRoute: FC<Props> = ({ isPrivate, permission, ...others }) => {
  if (isPrivate && !AuthStore.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  // Check if permission is requied for url & user has permission
  if (permission && !AuthStore.appUser[permission]) {
    return <Navigate to="/un-authorized" />;
  }

  return <others.element />;
};

export default PrivateRoute;
