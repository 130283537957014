import { IDaywiseTekuTekuScores, ITekuTekuScore } from '@shared-module/Interfaces';
import moment from 'moment';

export class TekuTekuModel {
  weeklyTarget: number = 0;
  weekId: number;
  dayWiseTekuTekuScores: DaywiseTekuScoresModel[];

  constructor(data?: Partial<TekuTekuModel>) {
    Object.assign(this, data);
  }

  public fetchpatientCurrentWeek(weekId: number): string[] {
    if (weekId) {
      if (this.dayWiseTekuTekuScores?.length) {
        return this.dayWiseTekuTekuScores
          .filter(x => x.weekId === weekId)
          ?.map(x => moment(x.exerciseStartDate).format('YYYY-MM-DD'));
      }
      return [];
    }
    return [];
  }

  public fetchWeeklyScore(weekId: number): number {
    if (weekId) {
      if (this.dayWiseTekuTekuScores.length) {
        return this.dayWiseTekuTekuScores
          .filter(x => x.weekId === weekId)
          ?.map(x => x.tekuTekuScore)
          .reduce((prev, current) => prev + current);
      }
      return 0;
    }
    return 0;
  }

  public fetchWeeklyTargetScore(weekId: number): number {
    if (weekId) {
      if (this.dayWiseTekuTekuScores.length) {
        return this.dayWiseTekuTekuScores
          .filter(x => x.weekId === weekId)
          ?.map(x => x.targetTekuTekuScore)
          .reduce((prev, current) => prev + current);
      }
      return 0;
    }
    return 0;
  }

  public get patientCurrentWeekScore(): number {
    if (this.weekId) {
      if (this.dayWiseTekuTekuScores.length) {
        return this.dayWiseTekuTekuScores
          .filter(x => x.weekId === this.weekId)
          ?.map(x => x.tekuTekuScore)
          .reduce((prev, current) => prev + current);
      }
      return 0;
    }
    return 0;
  }

  public get patientTekuScore(): number {
    if (this.dayWiseTekuTekuScores?.length) {
      return this.dayWiseTekuTekuScores[0].tekuTekuScore;
    }
    return 0;
  }

  public get patientTargetTekuScore(): number {
    if (this.dayWiseTekuTekuScores?.length) {
      return this.dayWiseTekuTekuScores[0].targetTekuTekuScore;
    }
    return 0;
  }

  public get currentDayTekuTekuScore(): DaywiseTekuScoresModel {
    if (this.dayWiseTekuTekuScores?.length) {
      const currentTekuScore = this.dayWiseTekuTekuScores?.find(
        x => moment(x.exerciseStartDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')
      );
      return currentTekuScore ? currentTekuScore : new DaywiseTekuScoresModel();
    }
    return new DaywiseTekuScoresModel();
  }

  static deserialize(apiData: ITekuTekuScore): TekuTekuModel {
    if (!apiData) {
      return new TekuTekuModel();
    }

    const data: Partial<TekuTekuModel> = {
      weeklyTarget: apiData.weeklyTarget,
      weekId: apiData.weekId,
      dayWiseTekuTekuScores: DaywiseTekuScoresModel.deserializeList(apiData.dayWiseTekuTekuScores),
    };

    return new TekuTekuModel(data);
  }

  static deserializeList(apiDataList: ITekuTekuScore[]): TekuTekuModel[] {
    return apiDataList
      ? apiDataList.map((apiData: ITekuTekuScore) => new TekuTekuModel(TekuTekuModel.deserialize(apiData)))
      : [];
  }
}

export class DaywiseTekuScoresModel {
  exerciseStartDate: string = '';
  exerciseCount: number = 0;
  exerciseCompletedCount: number = 0;
  excerciseCompletedPercentage: number = 0;
  tekuTekuScore: number = 0;
  targetTekuTekuScore: number = 0;
  weekId: number = 0;
  dayNumber: number = 0;

  constructor(data?: Partial<DaywiseTekuScoresModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IDaywiseTekuTekuScores): DaywiseTekuScoresModel {
    if (!apiData) {
      return new DaywiseTekuScoresModel();
    }

    const data: Partial<DaywiseTekuScoresModel> = {
      exerciseStartDate: apiData.exerciseStartDate,
      exerciseCount: apiData.exerciseCount,
      exerciseCompletedCount: apiData.exerciseCompletedCount,
      excerciseCompletedPercentage: apiData.excerciseCompletedPercentage,
      tekuTekuScore: apiData.tekuTekuScore ? apiData.tekuTekuScore : 0,
      targetTekuTekuScore: apiData.targetTekuTekuScore ? apiData.targetTekuTekuScore : 0,
      weekId: apiData.weekId,
      dayNumber: apiData.dayNumber,
    };

    return new DaywiseTekuScoresModel(data);
  }

  static deserializeList(apiDataList: IDaywiseTekuTekuScores[]): DaywiseTekuScoresModel[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IDaywiseTekuTekuScores) => new DaywiseTekuScoresModel(DaywiseTekuScoresModel.deserialize(apiData))
      )
      : [];
  }
}
