import { IEducation } from '@shared-module/Interfaces';

export class HCPEducation {
  id: number;
  occupation: string;
  college: string;

  constructor(data?: Partial<HCPEducation>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IEducation): HCPEducation {
    if (!apiData) {
      return new HCPEducation();
    }

    const data: Partial<HCPEducation> = {
      id: apiData.id,
      occupation: apiData.occupation,
      college: apiData.college,
    };

    return new HCPEducation(data);
  }

  static deserializeList(apiEducationList: IEducation[]): HCPEducation[] {
    return apiEducationList
      ? apiEducationList.map((apiData: IEducation) => new HCPEducation(HCPEducation.deserialize(apiData)))
      : [];
  }
}
