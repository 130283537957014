import { Alert, AlertColor, Snackbar } from '@mui/material';
import React,{ FC, useEffect } from 'react';

type Props = {
  isOpen: boolean;
  message: string;
  isError?: boolean;
  onClose?: (isClosed: boolean) => void;
}

const AlertModal: FC<Props> = ({ isOpen, message, onClose, isError = false }: Props) => {
  const [ open, setOpen ] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    onClose(false);
  };

  const getColor = (): AlertColor => {
    if(isError) {
      return 'error';
    }
    return 'success';
  }

  return <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
    <Alert onClose={handleClose} severity={getColor()} sx={{ width: '100%' }} variant="filled">
      {message}
    </Alert>
  </Snackbar>
}

export default AlertModal;