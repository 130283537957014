import { observable } from 'mobx';

class AuthErrorStore {
  @observable public hasError: boolean = false;

  show(): void {
    this.hasError = true;
  }

  hide(): void {
    this.hasError = false;
  }
}

export default new AuthErrorStore();
