import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  icon: {
    '&.MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
    '&.MuiListItemIcon-root': {
      color: 'rgba(255,255,255,0.5)',
    },
    '& svg': {
      width: '1em',
      height: '1em',
      fontSize: '1.3em',
      '&.MuiSvgIcon-root': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
  },
  text: {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
}));
