import { IDays, IMultimodalExerciseByWeek, ITMEPByLevel } from '@shared-module/Interfaces';
import { CategoryModel } from '.';
import { MEPExerciseCategory } from './MEPExerciseCategoryResponse.model';

export class TMEPByLevel {
  id: number;
  name: string;
  level: CategoryModel;
  multimodalExerciseByWeek: MultimodalExerciseByWeek[];
  selectedWeek: number;
  selectedWeekOfDays: MultimodalExerciseByWeek;

  constructor(data?: Partial<TMEPByLevel>) {
    Object.assign(this, data);
    this.id = data?.id || 0;
    this.name = data?.name || '';
    this.selectedWeek = 0;
    this.selectedWeekOfDays = data?.multimodalExerciseByWeek.find(x => x.weekNumber === this.selectedWeek + 1) || null;
  }

  static deserialize(apiData: ITMEPByLevel): TMEPByLevel {
    if (!apiData) {
      return new TMEPByLevel();
    }

    const data: Partial<TMEPByLevel> = {
      level: CategoryModel.deserialize(apiData.level),
      multimodalExerciseByWeek: MultimodalExerciseByWeek.deserializeList(apiData.multimodalExerciseByWeek),
    };

    return new TMEPByLevel(data);
  }
}

export class MultimodalExerciseByWeek {
  weekNumber: number;
  weekName: string;
  days: DayResponse[];

  constructor(data?: Partial<MultimodalExerciseByWeek>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IMultimodalExerciseByWeek): MultimodalExerciseByWeek {
    if (!apiData) {
      return new MultimodalExerciseByWeek();
    }

    const data: Partial<MultimodalExerciseByWeek> = {
      weekNumber: apiData.weekNumber,
      weekName: apiData.weekName,
      days: DayResponse.deserializeList(apiData.days),
    };

    return new MultimodalExerciseByWeek(data);
  }

  static deserializeList(apiDataList: IMultimodalExerciseByWeek[]): MultimodalExerciseByWeek[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IMultimodalExerciseByWeek) =>
          new MultimodalExerciseByWeek(MultimodalExerciseByWeek.deserialize(apiData))
      )
      : [];
  }
}

export class DayResponse {
  dayOfWeek: CategoryModel;
  mepExerciseCategories: MEPExerciseCategory[];

  constructor(data?: Partial<DayResponse>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IDays): DayResponse {
    if (!apiData) {
      return new DayResponse();
    }

    const data: Partial<DayResponse> = {
      dayOfWeek: CategoryModel.deserialize(apiData.dayOfWeek),
      mepExerciseCategories: MEPExerciseCategory.deserializeList(apiData.mepExerciseCategories),
    };

    return new DayResponse(data);
  }

  static deserializeList(apiDataList: IDays[]): DayResponse[] {
    return apiDataList ? apiDataList.map((apiData: IDays) => new DayResponse(DayResponse.deserialize(apiData))) : [];
  }
}
