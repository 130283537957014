import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      background: theme.palette.grey[100],
      '& > div:second-child': {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          height: '95%',
        },
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '50px',
        height: 'calc(100vh - 50px)',
      },
    },
    icon: {
      '&.MuiSvgIcon-root': {
        color: theme.palette.common.white,
      },
      '& svg': {
        width: '1em',
        height: '1em',
        fontSize: '1.3em',
      },
    },
    logo: {
      '& .cls-1': {
        fill: theme.palette.common.white,
      },
    },
    toolbarIcon: {
      justifyContent: 'flex-end',
      '&.MuiToolbar-root': {
        paddingRight: theme.spacing(1.9),
        [theme.breakpoints.down('sm')]: {
          paddingRight: theme.spacing(0),
        },
      },
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(2),
    },
    drawerPaper: {
      '&.MuiDrawer-paper': {
        background: '#1e4fa1',
      },
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      padding: theme.spacing(0, 2),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      textAlign: 'center',
      left: 10,
      paddingBottom: 10,
      width: 50,
    },
    logoutWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    logout: {
      '&.MuiButton-root': {
        textTransform: 'none',
        color: theme.palette.common.white,
        fontSize: 16,
        marginLeft: theme.spacing(3.125),
        minWidth: '100px',
      },
    },
    text: {
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
  });
