import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import usFlag from '../../Assets/Images/us_flag.png';
import jpFlag from '../../Assets/Images/japan_flag.png';
import cookies from 'js-cookie';
import { styles } from './LandingPage.styles';
import classNames from 'classnames';
import { IClasses } from '@shared-module/Interfaces';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/material';

interface Props {
  classes?: IClasses;
}

const LanguageSelect: FC<Props> = (props: Props) => {
  const { classes } = props;

  /* my logic start */
  const [ systemLanguage, setSystemLanguage ] = useState('');
  const locale = navigator.language;
  useEffect(() => {
    if (locale) {
      const languageFound = locale.split('-');
      setSystemLanguage(languageFound[0]);
    }
  }, [ locale ]);
  /*  logic end */
  const currentLanguageCode = cookies.get('i18next') || systemLanguage;
  const [ checkLanguage, setLanguage ] = useState(null);

  const handleClickEnglish = code => {
    setLanguageCode(code);
    i18next.changeLanguage(code);
    localStorage.setItem('language', code);
    window.location.reload();
  };

  const usButtonClass = classNames({
    [classes.enabled]: checkLanguage,
  });

  const japButtonClass = classNames({
    [classes.enabled]: !checkLanguage,
  });

  const setLanguageCode = (code: any) => {
    if (code === 'en') {
      setLanguage(true);
    } else {
      setLanguage(false);
    }
  };

  useEffect(() => {
    setLanguageCode(currentLanguageCode);
    document.body.dir = currentLanguageCode || 'ltr';
  }, [ currentLanguageCode ]);

  return (
    <Box
      className={classNames({
        [classes.flagContainer]: true,
      })}
    >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => handleClickEnglish('en')}
        className={usButtonClass}
        //disabled={currentLanguageCode!=='jp'?true:false}
      >
        <img src={usFlag} alt="us_flag" />
      </Button>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => handleClickEnglish('ja')}
        className={japButtonClass}
        //disabled={ currentLanguageCode!=='en'?true:false}
      >
        {' '}
        <img src={jpFlag} alt="jp_flag" />
      </Button>
    </Box>
  );
};

export default withStyles(styles)(observer(LanguageSelect));
