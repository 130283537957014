import React, { useEffect } from 'react';
import { Button, Dialog, DialogTitle, IconButton } from '@mui/material';
import { useStyles } from './PatientTestModal.styles';
import { Field } from 'mobx-react-form';
import { FormInputControl } from '@shared-module/Components';
import { observer } from 'mobx-react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  handleClose: () => void;
  field: Field;
  heightField?: Field;
  value: string;
  onSubmit: () => void;
};

const PatientTestModal = (props: Props) => {
  const classes = useStyles();
  const { open, handleClose, field, onSubmit, heightField } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (props.value) {
      field.set(props.value);
      heightField && heightField.set(props.value);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" className={classes.dialogContainer}>
      <DialogTitle className={classes.dialogTitle}>
        {heightField ? t('Calculate BMI') : t('Calculate Baseline Testing')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 18,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <FormInputControl form={field.bind()} field={field} />
      {heightField && <FormInputControl form={heightField?.bind()} field={heightField} />}
      <div className={classes.btnContainer}>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!field.isValid || (heightField && !heightField.isValid)}
        >
          {t('Save')}
        </Button>
      </div>
    </Dialog>
  );
};

export default observer(PatientTestModal);
