import { Level, Level_IDS } from '@shared-module/Enums/Levels.enum';
import { IPatientDailyReport, ITargetScore } from '@shared-module/Interfaces';
import { getScore } from '@utilities';

export class PatientDailyReportModel {
  id: number;
  patientId: number;
  weight: number;
  height: number;
  walkingAgeInMonth: number;
  walkingAgeInDays: number;
  walkingAgeYears: number;
  currentWalkingAgeInMonths: number;
  currentWalkingAgeInYears: number;
  remainingWalkingAgeInYears: number;
  remainingWalkingAgeInMonths: number;
  singleLegStand: number;
  timedUpAndGo: number;
  gaitSpeed: number;
  bmi: number;
  testReportDate: string;
  calculateWalkingAge: string;
  normative: string;
  sixMeterGaitSpeed: number;
  singleLegStandScore: number;
  timedUpAndGoScore: number;
  gaitSpeedScore: number;
  bmiScore: number;
  calculateCurrentScore: number;
  calculateBaseScore: number;
  calculateTargetScore: number;

  bmiCurrentLevelName: string;
  bmiTargetLevelName: string;
  bmiTargetLevelValue: number;

  gaitSpeedCurrentLevelName: string;
  gaitSpeedTargetLevelName: string;
  gaitSpeedTargetLevelValue: number;

  singleLegStandTargetLevelValue: number;
  singleLegStandCurrentLevelName: string;
  singleLegStandTargetLevelName: string;

  timedUpAndGoCurrentLevelName: string;
  timedUpAndGoTargetLevelName: string;
  timedUpAndGoTargetLevelValue: number;

  gaitSpeedLevel: string;
  bmiLevel: string;
  timedUpAndGoLevel: string;
  singleLegStandLevel: string;
  currentLevelAverage: number;
  levelName: Level;
  isConfirmed: boolean;
  bmiTargetLevelNumber: number;
  gaitSpeedTargetLevelNumber: number;
  singleLegStandTargetLevelNumber: number;
  timedUpAndGoTargetLevelNumber: number;
  targetLevelAverage: number;
  baselineLevelAverage: number;

  singleLegStandBaselineLevelNumber: number; 
  singleLegStandBaselineLevelName: string;
  timedUpAndGoBaselineLevelNumber: number;
  timedUpAndGoBaselineLevelName: string;
  gaitSpeedBaselineLevelNumber: number;
  gaitSpeedBaselineLevelName: string;
  bmiBaselineLevelNumber: number;
  bmiBaselineLevelName: string;


  constructor(data?: Partial<PatientDailyReportModel>) {
    Object.assign(this, data);
    this.levelName = this.levelNameFromCurrentLevel;
  }

  get isWalkingAbilityScore(): boolean {
    if (this.singleLegStand && this.timedUpAndGo && this.sixMeterGaitSpeed && this.bmi) return true;
    return false;
  }

  get patientScore(): ITargetScore[] {
    const targetScore = getScore(this.calculateBaseScore, this.calculateCurrentScore, this.calculateTargetScore);
    return targetScore;
  }

  static deserialize(apiData: IPatientDailyReport): PatientDailyReportModel {
    if (!apiData) {
      return new PatientDailyReportModel();
    }

    const data: Partial<PatientDailyReportModel> = {
      id: apiData.id,
      patientId: apiData.patientId,
      weight: apiData.weight,
      height: apiData.height,
      walkingAgeInMonth: apiData.walkingAgeInMonth,
      walkingAgeInDays: apiData.walkingAgeInDays,
      walkingAgeYears: apiData.walkingAgeYears,
      currentWalkingAgeInMonths: apiData.currentWalkingAgeInMonths,
      currentWalkingAgeInYears: apiData.currentWalkingAgeInYears,
      remainingWalkingAgeInMonths: apiData.remainingWalkingAgeInMonths,
      remainingWalkingAgeInYears: apiData.remainingWalkingAgeInYears,
      singleLegStand: apiData.singleLegStand,
      timedUpAndGo: apiData.timedUpAndGo,
      gaitSpeed: apiData.gaitSpeed,
      bmi: apiData.bmi,
      testReportDate: apiData.testReportDate,
      calculateWalkingAge: apiData.calculateWalkingAge,
      normative: apiData.normative,
      sixMeterGaitSpeed: apiData.sixMeterGaitSpeed,
      singleLegStandScore: apiData.singleLegStandScore,
      timedUpAndGoScore: apiData.timedUpAndGoScore,
      gaitSpeedScore: apiData.gaitSpeedScore,
      bmiScore: apiData.bmiScore,
      calculateCurrentScore: apiData.calculateCurrentScore === null ? 0 : apiData.calculateCurrentScore,
      calculateBaseScore: apiData.calculateBaseScore === null ? 0 : apiData.calculateBaseScore,
      calculateTargetScore:
        apiData.calculateTargetScore === null || apiData.calculateTargetScore === 0
          ? 100
          : apiData.calculateTargetScore,
      bmiCurrentLevelName: apiData.bmiCurrentLevelName,
      bmiTargetLevelName: apiData.bmiTargetLevelName,
      bmiTargetLevelValue: apiData.bmiTargetLevelValue,
      gaitSpeedCurrentLevelName: apiData.gaitSpeedCurrentLevelName,
      gaitSpeedTargetLevelName: apiData.gaitSpeedTargetLevelName,
      gaitSpeedTargetLevelValue: apiData.gaitSpeedTargetLevelValue,
      singleLegStandTargetLevelValue: apiData.singleLegStandTargetLevelValue,
      singleLegStandCurrentLevelName: apiData.singleLegStandCurrentLevelName,
      singleLegStandTargetLevelName: apiData.singleLegStandTargetLevelName,
      timedUpAndGoCurrentLevelName: apiData.timedUpAndGoCurrentLevelName,
      timedUpAndGoTargetLevelName: apiData.timedUpAndGoTargetLevelName,
      timedUpAndGoTargetLevelValue: apiData.timedUpAndGoTargetLevelValue,
      gaitSpeedLevel: apiData.gaitSpeedLevel,
      bmiLevel: apiData.bmiLevel,
      timedUpAndGoLevel: apiData.timedUpAndGoLevel,
      singleLegStandLevel: apiData.singleLegStandLevel,
      currentLevelAverage: apiData.currentLevelAverage,
      isConfirmed: apiData.isConfirmed,
      bmiTargetLevelNumber: apiData.bmiTargetLevelNumber,
      gaitSpeedTargetLevelNumber: apiData.gaitSpeedTargetLevelNumber,
      singleLegStandTargetLevelNumber: apiData.singleLegStandTargetLevelNumber,
      timedUpAndGoTargetLevelNumber: apiData.timedUpAndGoTargetLevelNumber,
      targetLevelAverage: apiData.targetLevelAverage,
      baselineLevelAverage: apiData.baselineLevelAverage,
      singleLegStandBaselineLevelNumber: apiData.singleLegStandBaselineLevelNumber,
      timedUpAndGoBaselineLevelNumber: apiData.timedUpAndGoBaselineLevelNumber,
      gaitSpeedBaselineLevelNumber: apiData.gaitSpeedBaselineLevelNumber,
      bmiBaselineLevelNumber: apiData.bmiBaselineLevelNumber,
      singleLegStandBaselineLevelName: apiData.singleLegStandBaselineLevelName,
      timedUpAndGoBaselineLevelName: apiData.timedUpAndGoBaselineLevelName,
      gaitSpeedBaselineLevelName: apiData.gaitSpeedBaselineLevelName,
      bmiBaselineLevelName: apiData.bmiBaselineLevelName,
    };

    return new PatientDailyReportModel(data);
  }

  static deserializeList(apiDataList: IPatientDailyReport[]): PatientDailyReportModel[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IPatientDailyReport) => new PatientDailyReportModel(PatientDailyReportModel.deserialize(apiData))
      )
      : [];
  }

  private get levelNameFromCurrentLevel(): Level {
    switch (this.currentLevelAverage) {
      case Level_IDS.BEGINNER:
        return Level.BEGINNER;
      case Level_IDS.POOR:
        return Level.POOR;
      case Level_IDS.BELOWNORMAL:
        return Level.BELOWNORMAL;
      case Level_IDS.NORMAL:
        return Level.NORMAL;
      case Level_IDS.ABOVENORMAL:
        return Level.ABOVENORMAL;
      case Level_IDS.ADVANCED:
        return Level.ADVANCED;
      case Level_IDS.EXPERT:
        return Level.EXPERT;
      default:
        return null;
    }
  }
}
