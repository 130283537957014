import { IHCPProfile } from '@shared-module/Interfaces';
import { calculateAge, formattedDate } from '@utilities';
import { HCPEducation, HCPExeprience, PatientSearchModel } from '.';

export class HCPProfile {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  dateOfJoining: string;
  educations?: HCPEducation[];
  experiences?: HCPExeprience[];
  specialist: string;
  patients?: PatientSearchModel[];
  patientCount: number;
  patientRecoveredCount?: number;
  email?: string;
  title?: string;

  constructor(data?: Partial<HCPProfile>) {
    Object.assign(this, data);
  }

  get fullName() {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : '';
  }

  get hcpAge(): number {
    return this.dateOfBirth && calculateAge(new Date(this.dateOfBirth));
  }

  get formatDateOfBirth(): string {
    return this.dateOfBirth && formattedDate(this.dateOfBirth);
  }

  get formatDateOfJoining(): string {
    return this.dateOfJoining && formattedDate(this.dateOfJoining);
  }

  static deserialize(apiData: IHCPProfile): HCPProfile {
    if (!apiData) {
      return new HCPProfile();
    }

    const data: Partial<HCPProfile> = {
      id: apiData.id,
      firstName: apiData.firstName,
      lastName: apiData.lastName,
      dateOfBirth: apiData.dateOfBirth,
      gender: apiData.gender,
      dateOfJoining: apiData.dateOfJoining,
      specialist: apiData.specialist,
      patientCount: apiData.patientCount,
      patientRecoveredCount: apiData.patientRecoveredCount,
      educations: HCPEducation.deserializeList(apiData.educations),
      experiences: HCPExeprience.deserializeList(apiData.experiences),
      patients: PatientSearchModel.deserializeList(apiData.patients),
      email: apiData.email,
      title: apiData.title,
    };

    return new HCPProfile(data);
  }

  static deserializeList(apiDataList: IHCPProfile[]): HCPProfile[] {
    return apiDataList
      ? apiDataList.map((apiData: IHCPProfile) => new HCPProfile(HCPProfile.deserialize(apiData)))
      : [];
  }

}
