import React, { FC } from 'react';
import { useStyles } from './CustomRadioButton.styles';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import { SelectField } from '@shared-module/Enums';

type Props = {
  handleSelectedState?: (flag: boolean, index?: number) => void;
  selectedObj?: number;
  errorMessage?: string;
  isError?: boolean;
  selected?: string;
};

const BooleanButtonGroup: FC<Props> = ({
  handleSelectedState,
  selectedObj,
  errorMessage,
  isError = false,
  selected,
}: Props) => {
  const classes = useStyles();

  const handleState = (selectedValue: boolean, index: number) => {
    handleSelectedState(selectedValue, index);
  };

  return (
    <>
      <div className={classes.booleanBtnContainer}>
        <div
          className={`${classes.booleanBtn} ${selected === SelectField.CHECKED ? 'active' : ''}`}
          onClick={() => handleState(true, selectedObj)}
        >
          <TripOriginIcon />
        </div>
        <div
          className={`${classes.booleanBtn} ${selected === SelectField.CROSSED ? 'active' : ''}`}
          onClick={() => handleState(false, selectedObj)}
        >
          <ClearIcon />
        </div>
      </div>
      {isError && (
        <Typography mt={1} variant="body1" color="error">
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default BooleanButtonGroup;
