import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import AppWrapper from './App';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs:[ 'en','ja' ],
    fallbackLng: 'en',
    detection: {
      order: [  'cookie' ,'htmlTag', 'localStorage', 'path', 'subdomain ' ],
      caches:  [ 'cookie' ],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
  });

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

serviceWorker.unregister();
