import { ICategory } from '@shared-module/Interfaces/MEP.interface';

export class CategoryModel {
  id: number;
  name: string;

  constructor(data?: Partial<CategoryModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: ICategory): CategoryModel {
    if (!apiData) {
      return new CategoryModel();
    }

    const data: Partial<CategoryModel> = {
      id: apiData.id,
      name: apiData.name,
    };

    return new CategoryModel(data);
  }

  static deserializeList(apiDataList: ICategory[]): CategoryModel[] {
    return apiDataList
      ? apiDataList.map((apiData: ICategory) => 
        new CategoryModel(CategoryModel.deserialize(apiData)))
      : [];
  }
}
