import { createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  mainContainer: {
    backgroundColor: '#1e4fa1',
    color: theme.palette.common.white,
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  mainHeading: {
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  goback:{
   
    '&.MuiButton-root': {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
      color: '#3a6293',
      borderRadius: 40,
      margin: '12px',
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 18,
      marginTop:'30px',
      
    },
    width: 280,

    [theme.breakpoints.down('sm')]: {
      width: 220,
    },
  },
}));