import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useStyles } from './SidebarList.styles';
import { useTranslation } from 'react-i18next';

type Props = {
  menuList: any[];
};

const SidebarList: FC<Props> = ({ menuList }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {menuList.map(nav => {
       
        return (
          <NavLink to={nav.to} className={classes.text} key={nav.label}>
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <nav.icon />
              </ListItemIcon>
              <ListItemText primary={t(nav.label)} />
            </ListItem>
          </NavLink>
        );
      })}
    </>
  );
};

export default SidebarList;
