import { CategoryModel, MEPExerciseCategory, MultimodalExerciseLevel, ProgramByLevel } from '@shared-module/Models';
import { TMEPByLevel } from '@shared-module/Models/TMEPByLevel.model';
import { action, makeAutoObservable } from 'mobx';

export class ProgramStore {
  levels: CategoryModel[] = null;
  defaultLevel: CategoryModel = { id: 1, name: 'Level 1' };
  selectedLevel: CategoryModel = { id: 1, name: 'Level 1' };
  multimodalExerciseDetail: MultimodalExerciseLevel = new MultimodalExerciseLevel();
  multimodalProgramDetail: TMEPByLevel = new TMEPByLevel();
  exercisesDetail: MEPExerciseCategory[] = [];
  levelProgramDetail: ProgramByLevel[] = [];

  constructor() {
    makeAutoObservable(this, {});
  }

  @action
  setDefaultLevels(levels: CategoryModel[]) {
    this.levels = levels;
  }

  @action
  setLevel(level: CategoryModel) {
    this.selectedLevel = level;
  }

  @action
  setMultimodalExerciseDetail(data: MultimodalExerciseLevel) {
    this.multimodalExerciseDetail = data;
  }

  @action
  setTMEPDetails(data: TMEPByLevel) {
    this.multimodalProgramDetail = { ...data };
  }

  @action
  setExercisesDetail(data: MEPExerciseCategory[]) {
    this.exercisesDetail = [ ...data ];
  }

  @action
  setLevelProgramDetail(data: ProgramByLevel[]) {
    this.levelProgramDetail = [ ...data ];
  }
}
