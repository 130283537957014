import { IMEPExerciseCategory } from '@shared-module/Interfaces/ExerciseByLevel.interface';
import { MultimodalExerciseLevel } from '.';

export class MEPExerciseCategory {
  id: number;
  name: string;
  multimodalExercises: MultimodalExerciseLevel[];
  selectedExersice: string[];

  constructor(data?: Partial<MEPExerciseCategory>) {
    Object.assign(this, data);
    this.selectedExersice = data?.multimodalExercises?.map(x => x.multimodalExercise?.name) || [];
  }

  static deserialize(apiData: IMEPExerciseCategory): MEPExerciseCategory {
    if (!apiData) {
      return new MEPExerciseCategory();
    }

    const data: Partial<MEPExerciseCategory> = {
      id: apiData.id,
      name: apiData.name,
      multimodalExercises: MultimodalExerciseLevel.deserializeList(apiData.multimodalExercises),
    };

    return new MEPExerciseCategory(data);
  }

  static deserializeList(apiDataList: IMEPExerciseCategory[]): MEPExerciseCategory[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IMEPExerciseCategory) => new MEPExerciseCategory(MEPExerciseCategory.deserialize(apiData))
      )
      : [];
  }
}
