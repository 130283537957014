import React from 'react';
import FusionCharts from 'fusioncharts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

type Props = {
  dataSource: any;
};

ReactFC.fcRoot(FusionCharts, Widgets, FusionTheme);

FusionCharts.options['license']({
  key: 'prD4C-8eiA7A3A3C7D6G4C4F4I4C7B2D3D2nyqE1C3fd1' +
    'npaE4D4tlA-21D7E4F4F1F1E1F4F1C11C7C2E5E5E2F2D1hwqD1B5D1aG4A19A32twbC6D3G4lhJ-7A9C11A5B-' +
    '13ddA2I3A1B9B3D7A2B4G2H3H1F-7smC8B2XB4cetB8A7A5mxD3SG4F2tvgB2A3B2E4C3I3A8B3E5A4A2B3C2G4J-7==',
  creditLabel: false,
});

const CustomGaugeChart = (props: Props) => {
  return <ReactFC type="angulargauge" width={300} height={180} dataFormat="JSON" dataSource={props.dataSource} />;
};

export default CustomGaugeChart;
