import { PatientDailyReportModel, PatientSearchModel, PatientTMEPModel } from '@shared-module/Models';
import { makeAutoObservable } from 'mobx';

export class HCPPatientStore {
  searchValue: string = null;
  patientSearchDetails: PatientSearchModel[] = null;
  selectedPatientDetails: PatientSearchModel = new PatientSearchModel();
  patientTMEP: PatientTMEPModel = null;
  patientReports: PatientDailyReportModel[] = [];
  isConfirmedTest: boolean = false;
  isTestingDone: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setSearchValue(value: string) {
    this.searchValue = value;
  }

  setPatientSearchData(data: PatientSearchModel[]) {
    this.patientSearchDetails = data;                         
  }

  setSelectedPatientDetails(data: PatientSearchModel) {
    this.selectedPatientDetails = data;
  }

  setPatientReports(data: PatientDailyReportModel[]) {
    this.selectedPatientDetails.patientReport = this.patientReports = data;
  }

  setPatientTMEP(data: PatientTMEPModel) {
    this.patientTMEP = data;
  }

  setConfirmedTest(isConfirmed: boolean) {
    this.isConfirmedTest = isConfirmed;
  }

  setTestingStatus(isTestingDone: boolean) {
    this.isTestingDone = isTestingDone;
  }
}
