import { makeStyles } from '@mui/styles'; 
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputLabel-root': {
      top: '-27px',
      transform: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 10px',
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: '5px 8px 8px',
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: '0px 1px 4px',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
  fieldContainer: {
    margin: '40px 0px 5px',
  },
  disabled: {
    '& .MuiOutlinedInput-root': {
      background: theme.palette.grey[200],
      pointerEvents: 'none',
    },
  },
}));
