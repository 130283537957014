import React, { FC } from 'react';
import { useStyles } from './BooleanBtnGroup.styles';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';

type Props = {
  handleSelectedState?: (flag: boolean, name?: string, isSelected?: boolean) => void;
  selectedObj?: any;
  active: boolean;
  isSelected?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
};

const BooleanButtonGroup: FC<Props> = ({
  handleSelectedState,
  selectedObj,
  active,
  errorMessage,
  isSelected = true,
  isSubmitted,
}: Props) => {
  const classes = useStyles();

  const handleState = (selectedvlue: boolean, name = '', isSelected: boolean) => {
    handleSelectedState(selectedvlue, name, isSelected);
  };

  return (
    <div className={classes.booleanBtnContainer}>
      <div
        className={`${classes.booleanBtn} ${active ? 'active' : ''}`}
        onClick={() => handleSelectedState(true, selectedObj, true)}
      >
        <TripOriginIcon />
      </div>
      <div
        className={`${classes.booleanBtn} ${isSelected ? (active ? '' : 'active') : ''}`}
        onClick={() => handleState(false, selectedObj, true)}
      >
        <ClearIcon />
      </div>
      {isSubmitted && !isSelected && (
        <Typography mt={1} variant="body1" color="error">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default BooleanButtonGroup;
