import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  monthsData: any;
  setMonth: (month) => void;
};

const CalendarMonthTable: FC<Props> = ({ monthsData, setMonth }: Props) => {
  const months = [];
  const { t } = useTranslation();

  const renderMonths = () => {
    monthsData.map(data => {
    
      return months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            setMonth(data);
          }}
        >
          <span>{t(data)}</span>
        </td>
      );
    });
  };

  const renderMonthList = (): ReactNode => {
    renderMonths();
    const rows = [];
    let cells = [];
    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    
    return rows.map((years, index) => {
      
      return <tr key={index}>{years}</tr>;
    });
  };

  return (
    <table className="calendar-month">
      <thead>
        <tr>
          <th colSpan={4}>{t('Select a Month')}</th>
        </tr>
      </thead>
      <tbody>{renderMonthList()}</tbody>
    </table>
  );
};

export default CalendarMonthTable;
