export class WalkingAgeAboutModel {
  dateOfBirth: string;
  isFemale: boolean;
  weight: number;
  height: number;
  isDiagnosed: boolean;

  constructor(data?: Partial<any>) {
    this.dateOfBirth = data?.dateOfBirth || '';
    this.isFemale = data?.gender || false;
    this.weight = data?.weight || 0;
    this.height = data?.height || 0;
    this.isDiagnosed = data?.isDiagnosed || false;
  }
}

export class WalkingAgeWalkingSpeed {
  existingTestWalkingSpeedValue?: number;
  constructor(data?: Partial<any>) {
    this.existingTestWalkingSpeedValue = data?.speed || 0;
  }
}

export class WalkingAgeHealthCondition {
  backPain: boolean = false;
  legsPain: boolean = false;
  fallWithinLast2Years: boolean = false;
  fivePrescribedMedications: boolean = false;
  hospitalStay: boolean = false;
  jointReplacementOperation: boolean = false;
  hadStroke: boolean = false;
  lowerLimbOsteoarthritis: boolean = false;
  diabetes: boolean = false;
  cognitiveImpairment: boolean = false;
  depression: boolean = false;
  multipleSclerosis: boolean = false;
  parkinsons: boolean = false;

  constructor(data?: Partial<WalkingAgeHealthCondition>) {
    Object.assign(this, data);
  }
}

export class HealthScreeningModel {
  depressionScore: number = 0;
  cognitiveImpairmentScore: number = 0;
  osteoarthritisScore:  number = 0;
  diabetesScore: number = 0;

  constructor(data?: Partial<HealthScreeningModel>) {
    Object.assign(this, data);
  }
}
