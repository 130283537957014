import React, { FC, ReactChild, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AuthStore } from '@shared-module/Stores';
import { Loader } from '@shared-module/Components';
import { defer, finalize } from 'rxjs';
import { AxiosError } from 'axios';

type Props = {
  children: ReactChild;
};

const Authorization: FC<Props> = ({ children }) => {
  const [ isLoading, setLoading ] = useState(true);

  useEffect(() => {
    const subscription = defer(() => AuthStore.getUserProfile())
      .pipe(finalize(() => setLoading(false)))
      .subscribe({ error: (err: AxiosError) => console.log(err.response, err.code) });
    return () => subscription.unsubscribe();
  }, []);

  return isLoading ? <Loader /> : <>{children}</>;
};

export default observer(Authorization);
