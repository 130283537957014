import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    background: '#ffffffab',
    height: '100vh',
    position: 'absolute',
    zIndex: 99999,
    width: '100%',
  },
}));
