import { IPatientInvite } from '@shared-module/Interfaces';

export class PatientInviteModel {
  id: number;
  hcpId: number;
  patientId: number;
  firstName: string;
  lastName: string;
  email: string;
  createdOn: string;

  constructor(data?: Partial<PatientInviteModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IPatientInvite): PatientInviteModel {
    if (!apiData) {
      return new PatientInviteModel();
    }

    const data: Partial<PatientInviteModel> = {};

    return new PatientInviteModel(data);
  }

  static deserializeList(apiDataList: IPatientInvite[]): PatientInviteModel[] {
    return apiDataList
      ? apiDataList.map((apiData: IPatientInvite) => new PatientInviteModel(PatientInviteModel.deserialize(apiData)))
      : [];
  }
}
