import { IPatientWeeklyTest, IWalkingAgeResponse } from '@shared-module/Interfaces';

export class WalkingAgeResponseModel {
  patientId: number;
  patientWeeklyTest: PatientWeeklyTestModel;

  constructor(data?: Partial<WalkingAgeResponseModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IWalkingAgeResponse): WalkingAgeResponseModel {
    if (!apiData) {
      return new WalkingAgeResponseModel();
    }

    const data: Partial<WalkingAgeResponseModel> = {
      patientId: apiData.patientId,
      patientWeeklyTest: PatientWeeklyTestModel.deserialize(apiData.patientWeeklyTest),
    };

    return new WalkingAgeResponseModel(data);
  }
}

export class PatientWeeklyTestModel {
  actualAge: number;
  actualAgeMonths: number;
  actualAgeYears: number;
  actualGaitSpeed: number;
  ageDifference: number;
  backPain: boolean;
  belowNormativeGaitSpeedForAge: boolean;
  bmiAbove: boolean;
  bmiBelow: boolean;
  cognitiveImpairment: boolean;
  depression: boolean;
  derivedGaitSpeed: number;
  diabetes: boolean;
  displayHealthRecommendations: boolean;
  displayRecommendations: boolean;
  hadStroke: boolean;
  historyOfFall: boolean;
  hospitalStay: boolean;
  jointReplacementOperation: boolean;
  legsPain: boolean;
  lowerLimbOsteoarthritis: boolean;
  monthsTo400mDifficulty: number;
  monthsToDependancy: number;
  moreThan5Medications: boolean;
  multipleSclerosis: boolean;
  parkinsons: boolean;
  walkingAge: number;
  walkingAgeBelowAverage: boolean;
  walkingAgeMonths: number;
  walkingAgeYears: number;
  yearsTo400mDifficulty: number;
  yearsToDependancy: number;

  constructor(data?: Partial<PatientWeeklyTestModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IPatientWeeklyTest): PatientWeeklyTestModel {
    if (!apiData) {
      return new PatientWeeklyTestModel();
    }

    const data: Partial<PatientWeeklyTestModel> = {
      actualAge: apiData.actualAge,
      actualAgeMonths: apiData.actualAgeMonths,
      actualAgeYears: apiData.actualAgeYears,
      actualGaitSpeed: apiData.actualGaitSpeed,
      ageDifference: apiData.ageDifference,
      backPain: apiData.backPain,
      belowNormativeGaitSpeedForAge: apiData.belowNormativeGaitSpeedForAge,
      bmiAbove: apiData.bmiAbove,
      bmiBelow: apiData.bmiBelow,
      cognitiveImpairment: apiData.cognitiveImpairment,
      depression: apiData.depression,
      derivedGaitSpeed: apiData.derivedGaitSpeed,
      diabetes: apiData.diabetes,
      displayHealthRecommendations: apiData.displayHealthRecommendations,
      displayRecommendations: apiData.displayRecommendations,
      hadStroke: apiData.hadStroke,
      historyOfFall: apiData.historyOfFall,
      hospitalStay: apiData.hospitalStay,
      jointReplacementOperation: apiData.jointReplacementOperation,
      legsPain: apiData.legsPain,
      lowerLimbOsteoarthritis: apiData.lowerLimbOsteoarthritis,
      monthsTo400mDifficulty: apiData.monthsTo400mDifficulty,
      monthsToDependancy: apiData.monthsToDependancy,
      moreThan5Medications: apiData.moreThan5Medications,
      multipleSclerosis: apiData.multipleSclerosis,
      parkinsons: apiData.parkinsons,
      walkingAge: apiData.walkingAge,
      walkingAgeBelowAverage: apiData.walkingAgeBelowAverage,
      walkingAgeMonths: apiData.walkingAgeMonths,
      walkingAgeYears: apiData.walkingAgeYears,
      yearsTo400mDifficulty: apiData.yearsTo400mDifficulty,
      yearsToDependancy: apiData.yearsToDependancy,
    };

    return new PatientWeeklyTestModel(data);
  }
}
