import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  dataGridContainer: {
    width: '100%',
    height: '70vh',
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#1e4fa1',
      color: theme.palette.common.white,
      fontSize: '18px',
    },
    '& .MuiDataGrid-menuIconButton': {
      color: theme.palette.common.white,
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.common.white,
    },
    '& .MuiDataGrid-cell': {
      fontSize: '18px',
    },
  },
}));
