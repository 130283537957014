import { action, observable, makeObservable } from 'mobx';
class UIStore {
  @observable public pageLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public setPageLoader(flag: boolean): void {
    this.pageLoading = flag;
  }
}

export default new UIStore()