import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  booleanBtn: {
    backgroundColor: '#1e4fa1',
    width: 80,
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: '5px',
    opacity: 0.5,
    cursor: 'pointer',
    '& svg': {
      color: theme.palette.common.white,
      width: '35px',
      height: '35px',
    },
    '&:first-child': {
      marginRight: '20px',
    },
    '&.active': {
      opacity: 1,
    },
  },
  booleanBtnContainer: {
    display: 'flex',
  },
}));
