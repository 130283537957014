import {
  IPatientTMEP,
  IDailyPatientActivityResponse,
  IMultimodalExerciseProgram,
} from '@shared-module/Interfaces/MEP.interface';
import { CategoryModel, MultimodalExerciseProgram, PatientSearchModel } from '.';
import { currentDate, getFormattedDate } from '@utilities';

export class PatientTMEPModel extends CategoryModel {
  dailyPatientActivityResponses: DailyPatientActivityResponseModel[] = [];
  hasTestingDay: boolean;
  patient: PatientSearchModel;
  todayExercises: MultimodalExerciseProgram[] = [];
  selectedExercise: MultimodalExerciseProgram;
  completedExerciseIds: number[] = [];
  isTodayExercisesCompleted: boolean;

  constructor(data?: Partial<PatientTMEPModel>) {
    super();
    Object.assign(this, data);
  }

  get isProgramAssigned(): boolean {
    if(this.dailyPatientActivityResponses.length > 0) {
      return true;
    }
    return false
  }

  static deserialize(apiData: IPatientTMEP): PatientTMEPModel {
    if (!apiData) {
      return new PatientTMEPModel();
    }

    const date = currentDate();

    const exercise = apiData.dailyPatientActivityResponses?.reduce((prev, curr) => {
      const test = curr.multimodalExercisePrograms.filter(
        p => getFormattedDate(p.tmepDayofWeeks[0].exerciseStartDate) === date
      );

      const newArray = prev.concat(test);
      return newArray;
    }, Array<IMultimodalExerciseProgram>());

    const selectedExercise = exercise?.find(x => x.isExerciseCompleted === false);
    const completedExercises = exercise?.map(x => {
      return x.isExerciseCompleted ? x.id : 0;
    });

    const todayExerciseCompleted = exercise?.length && exercise?.every(x => x.isExerciseCompleted === true);

    const data: Partial<PatientTMEPModel> = {
      id: apiData.id,
      name: apiData.name,
      hasTestingDay: apiData.hasTestingDay,
      dailyPatientActivityResponses: DailyPatientActivityResponseModel.deserializeList(
        apiData.dailyPatientActivityResponses
      ),
      patient: PatientSearchModel.deserialize(apiData.patient),
      todayExercises: MultimodalExerciseProgram.deserializeList(exercise),
      selectedExercise: MultimodalExerciseProgram.deserialize(selectedExercise),
      completedExerciseIds: completedExercises,
      isTodayExercisesCompleted: todayExerciseCompleted,
    };

    return new PatientTMEPModel(data);
  }
}

export class DailyPatientActivityResponseModel {
  id: number;
  day: string;
  multimodalExercisePrograms: MultimodalExerciseProgram[] = [];
  isAllExerciseCompleted: boolean;
  weekId: number;

  constructor(data?: Partial<DailyPatientActivityResponseModel>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IDailyPatientActivityResponse): DailyPatientActivityResponseModel {
    if (!apiData) {
      return new DailyPatientActivityResponseModel();
    }

    const week =
      apiData.multimodalExercisePrograms?.length > 0
        ? apiData.multimodalExercisePrograms[0]?.multimodalExerciseLevel.weekId
        : 0;

    const allExerciseCompleted =
      apiData.multimodalExercisePrograms?.length > 0
        ? apiData.multimodalExercisePrograms?.every(x => x.isExerciseCompleted === true)
        : false;

    const data: Partial<DailyPatientActivityResponseModel> = {
      id: apiData.id,
      day: apiData.day,
      multimodalExercisePrograms: MultimodalExerciseProgram.deserializeList(apiData.multimodalExercisePrograms),
      isAllExerciseCompleted: allExerciseCompleted,
      weekId: week,
    };

    return new DailyPatientActivityResponseModel(data);
  }

  

  static deserializeList(apiDataList: IDailyPatientActivityResponse[]): DailyPatientActivityResponseModel[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IDailyPatientActivityResponse) =>
          new DailyPatientActivityResponseModel(DailyPatientActivityResponseModel.deserialize(apiData))
      )
      : [];
  }
}
