import { ITargetScore } from '@shared-module/Interfaces';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import 'moment/locale/ja';
import 'moment/locale/en-in';
import  cookies  from 'js-cookie';


export function calculateAge(birthday: Date): number {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function calculateBMI(weight: number, height: number): number {
  const heightInMeter = parseFloat((height/100).toFixed(2));
  return parseFloat((weight / (heightInMeter * heightInMeter)).toFixed(2));
}

export function formatPhoneNumber(phoneNumberString: string): string {
  if (!phoneNumberString) return '-';
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return '-';
}

export function getHcpIdFromLocalStorage(): string {
  return localStorage.getItem('hcpId');
}

export function getPatientIdFromLocalStorage(): string {
  return localStorage.getItem('patientId');
}

export function formattedDate(dateString: string): string {
  const date = moment(dateString);
  const currentLanguageCode = cookies.get('i18next')
  if (!currentLanguageCode||currentLanguageCode==='en'){
    return date.isValid() ? date.locale('en-in').format('D MMM YYYY') : 'N/A';
  }else{
    return date.isValid() ? date.locale('ja').format('D MMM YYYY') : 'N/A';
  }

}

export function currentDate(): string {
  return moment().format('YYYY-MM-DD');
}

export function getFormattedDate(date: string, displayFormat?: string): string {
  if (!date) {
    return null;
  }
  const _displayFormat = displayFormat || 'YYYY-MM-DD'
  return moment(date).format(_displayFormat);
}

export function getValueForDefault(value: number, defaultString?: string): string {
  if (value) {
    return `${value.toFixed(2)} ${defaultString}`;
  }

  return 'N/A';
}

export function formatAge(years: number, months: number, isRemainingAge = false): string {
  if (!Boolean(years) && !Boolean(months)) return '';
  const year = years > 1 ? 'years' : 'year';
  const month = months > 1 ? 'months' : 'month';
  if (years != 0 && months == 0) {
    return isRemainingAge ? `You have ${years} ${year} walking life remaining` : `${years} ${year}`;
  }
  if (years == 0 && months != 0) {
    return isRemainingAge ? `You have ${months} ${month} walking life remaining` : `${months} ${month}`;
  }
  return isRemainingAge ? `You have ${years} ${year} and ${months} ${month} walking life remaining` : `${years} ${year} ${months} ${month}`;
}

export function getScore(baseScore: number, currentScore: number, targetScore: number): ITargetScore[] {
  if (baseScore === currentScore) {
    return [
      { score: `Base - ${baseScore}`, val: 0 },
      {
        score: `Current - ${currentScore}`,
        val: currentScore,
      },
      { score: `Target - ${targetScore}`, val: 100 - currentScore },
    ];
  }
  if (currentScore === 100 && baseScore < 100) {
    return [
      { score: `Base - ${baseScore}`, val: baseScore },
      {
        score: `Current - ${currentScore}`,
        val: currentScore - baseScore,
      },
      { score: `Target - ${0}`, val: 0 },
    ];
  }
  if (currentScore === 100 && baseScore === 100) {
    return [
      { score: `Base - ${baseScore}`, val: 0 },
      {
        score: `Current - ${currentScore}`,
        val: 100,
      },
      { score: `Target - ${0}`, val: 0 },
    ];
  }

  return [
    { score: `Base - ${baseScore}`, val: baseScore },
    {
      score: `Current - ${currentScore}`,
      val: currentScore - baseScore,
    },
    { score: `Target - ${targetScore}`, val: 100 - currentScore },
  ];
}

export function getAgeInYears(dateOfBirth: string): number {
  const currentDate = moment(new Date(), 'YYYY-MM-DD');
  return currentDate.diff(dateOfBirth, 'years');
}

export function buildParamString(params: { [key: string]: any }): string {
  const paramsArray: string[] = [];
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = typeof params[key] === 'number' && isFinite(params[key]) ? params[key] : params[key] || '';
      paramsArray.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  return paramsArray.join('&');
}

export function getArray(max: number, min: number): number[] {
  return Array.from({ length: max + 1 - min }, (v, k) => k + min);
}

export function getDecodedAuthToken(accessToken: string): any {
  if (!accessToken || accessToken === '') {
    return null;
  }

  const decode: any = jwt_decode(accessToken);
  return decode;
}