import { IExperience } from '@shared-module/Interfaces';

export class HCPExeprience {
  id: number;
  hospital: string;
  clinic: string;

  constructor(data?: Partial<HCPExeprience>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IExperience): HCPExeprience {
    if (!apiData) {
      return new HCPExeprience();
    }

    const data: Partial<HCPExeprience> = {
      id: apiData.id,
      hospital: apiData.hospital,
      clinic: apiData.clinic,
    };

    return new HCPExeprience(data);
  }

  static deserializeList(apiExexperienceList: IExperience[]): HCPExeprience[] {
    return apiExexperienceList
      ? apiExexperienceList.map((apiData: IExperience) => new HCPExeprience(HCPExeprience.deserialize(apiData)))
      : [];
  }
}
