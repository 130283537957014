export const url = {
  login: 'api/Authentication/Login',
  invitePatient: 'api/Invite',
  inviteAccepted: 'api/Invite',
  patientRegistration: 'api/Invite/patientRegistration',
  register: 'api/Patient/registration',
  tmepListById: 'api/TMEP/GetTMEPByHCP',
  patientSearch: 'api/Patient/patients',
  levels: 'api/Level',
  exerciseCategory: 'api/ExerciseCategory',
  multimodalExerciseLevel: 'api/MultimodalExerciseLevel/MultimodalExercisesByLevel',
  multimodalExerciseById: 'api/MultimodalExerciseLevel',
  dayOfWeek: 'api/DayOfWeek',
  saveProgram: 'api/TMEP',
  programById: 'api/TMEP',
  saveWalkingAge: 'api/HCP/calculateWalkingAge',
  patientById: 'api/Patient',
  completeExercise: 'api/Patient/patientExercise',
  patientTestReport: 'api/patient/patientTestReport',
  assignProgram: 'api/TMEP/AssignTMEP',
  startProgram: '/api/PatientProgram',
  weeklyProgress: '/api/PatientProgram',
  getTekuTekuScore: '/api/TekuTekuScore',
  assignPrivateProgram: '/api/TMEP/PatientTMEP',
  multimodalExerciseProgram: 'api/TMEP/MultimodalExerciseProgram',
  multimodalExercises: 'api/TMEP/multimodalExercises',
  saveModifiedProgram: 'api/TMEP/patientTMEPModifiedProgram',
  currentDayExercises: 'api/TMEP/todayPatientExercises',
  fetchTekuTekuScore: 'api/TekuTekuScore/weeklyTekuTeku',
  programByLevel: 'api/MultimodalExerciseLevel/melAccordingToLevelProgression',
  saveProgramByLevel: 'api/TMEP/melAccToLevelProgression',
  fetchAdminDetail: '/api/Admin',
  sendPasswordLink: 'api/ForgotPassword/sendForgotPasswordLink',
  passwordLinkValidity: 'api/ForgotPassword/checkLinkValidity',
  resetPassword: 'api/ForgotPassword/resetPassword',
  hcp: 'api/HCP',
  removeHCP: 'api/HCP/delete',
  removePatient: 'api/Patient/delete',
  editPatient: '/api/Patient/edit',
};
