import { IMultimodalExerciseProgram } from '@shared-module/Interfaces';
import { MultimodalExerciseLevel, TmepDayOfWeeksModel } from '.';

export class MultimodalExerciseProgram {
  id: number;
  sets: number;
  reps: number;
  isExerciseCompleted: boolean;
  multimodalExerciseLevel: MultimodalExerciseLevel;
  tmepDayofWeeks: TmepDayOfWeeksModel[];

  constructor(data?: Partial<MultimodalExerciseProgram>) {
    Object.assign(this, data)
  }

  static deserialize(apiData: IMultimodalExerciseProgram): MultimodalExerciseProgram {
    if (!apiData) {
      return new MultimodalExerciseProgram();
    }

    const data: Partial<MultimodalExerciseProgram> = {
      id: apiData.id,
      sets: apiData.sets,
      reps: apiData.reps,
      isExerciseCompleted: apiData.isExerciseCompleted,
      multimodalExerciseLevel: MultimodalExerciseLevel.deserialize(apiData.multimodalExerciseLevel),
      tmepDayofWeeks: TmepDayOfWeeksModel.deserializeList(apiData.tmepDayofWeeks),
    };

    return new MultimodalExerciseProgram(data);
  }

  static deserializeList(apiDataList: IMultimodalExerciseProgram[]): MultimodalExerciseProgram[] {
    return apiDataList
      ? apiDataList.map((apiData: IMultimodalExerciseProgram) =>
        new MultimodalExerciseProgram(MultimodalExerciseProgram.deserialize(apiData)))
      : [];
  }
}