import { IMultimodalExerciseLevel, IMultiModelExcerciseLevelDayWise } from '@shared-module/Interfaces/MEP.interface';
import { getArray } from '@utilities';
import { CategoryModel } from '.';
import { MultimodalExercise } from './MultimodalExercise.model';

export class MultimodalExerciseLevel {
  id: number;
  duration: number;
  sets: number;
  reps: number;
  minReps: number;
  maxReps: number;
  defaultDayOfWeek: string;
  weekId: number;
  levelId: number;
  levelProgressionId: number;
  multimodalExercise: MultimodalExercise;
  level: CategoryModel;
  multiModelExcerciseLevelDayWise: MultiModelExerciseLevelDayWise[];
  selectedDays: string[];

  constructor(data?: Partial<MultimodalExerciseLevel>) {
    Object.assign(this, data);
  }

  public get repititions(): number[] {
    return getArray(this.maxReps, this.minReps);
  }

  public get exerciseSets(): number[] {
    return getArray(4, 1);
  }

  public get exerciseMaxReps(): number[] {
    return getArray(22, this.minReps);
  }

  static deserialize(apiData: IMultimodalExerciseLevel): MultimodalExerciseLevel {
    if (!apiData) {
      return new MultimodalExerciseLevel();
    }

    const data: Partial<MultimodalExerciseLevel> = {
      id: apiData.id,
      duration: apiData.duration,
      sets: apiData.sets,
      reps: apiData.reps,
      minReps: apiData.minReps,
      maxReps: apiData.maxReps,
      weekId: apiData.weekId,
      levelId:  apiData.levelId,
      levelProgressionId: apiData.levelProgressionId,
      defaultDayOfWeek: apiData.defaultDayOfWeek,
      multiModelExcerciseLevelDayWise: MultiModelExerciseLevelDayWise.deserializeList(
        apiData.multiModelExcerciseLevelDayWise
      ),
      multimodalExercise: MultimodalExercise.deserialize(apiData.multimodalExercise),
      level: CategoryModel.deserialize(apiData.level),
    };

    return new MultimodalExerciseLevel(data);
  }

  static deserializeList(apiDataList: IMultimodalExerciseLevel[]): MultimodalExerciseLevel[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IMultimodalExerciseLevel) =>
          new MultimodalExerciseLevel(MultimodalExerciseLevel.deserialize(apiData))
      )
      : [];
  }
}

export class MultiModelExerciseLevelDayWise {
  id: number;
  dayOfWeekId: number;
  multimodalExerciseLevelId: number;

  constructor(data?: Partial<MultiModelExerciseLevelDayWise>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IMultiModelExcerciseLevelDayWise): MultiModelExerciseLevelDayWise {
    if (!apiData) {
      return new MultiModelExerciseLevelDayWise();
    }

    const data: Partial<MultiModelExerciseLevelDayWise> = {
      id: apiData.id,
      dayOfWeekId: apiData.dayOfWeekId,
      multimodalExerciseLevelId: apiData.multimodalExerciseLevelId,
    };

    return new MultiModelExerciseLevelDayWise(data);
  }

  static deserializeList(apiDataList: IMultiModelExcerciseLevelDayWise[]): MultiModelExerciseLevelDayWise[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IMultiModelExcerciseLevelDayWise) =>
          new MultiModelExerciseLevelDayWise(MultiModelExerciseLevelDayWise.deserialize(apiData))
      )
      : [];
  }
}
