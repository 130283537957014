import { IMultimodalExercise } from '@shared-module/Interfaces/MEP.interface';
import { CategoryModel } from '.';

export class MultimodalExercise {
  id: number;
  name: string;
  notes: string;
  exerciseImageName: string;
  exerciseCategory: CategoryModel;

  constructor(data?: Partial<MultimodalExercise>) {
    Object.assign(this, data);
  }

  static deserialize(apiData: IMultimodalExercise): MultimodalExercise {
    if (!apiData) {
      return new MultimodalExercise();
    }

    const data: Partial<MultimodalExercise> = {
      id: apiData.id,
      name: apiData.name,
      notes: apiData.notes,
      exerciseCategory: CategoryModel.deserialize(apiData.exerciseCategory),
      exerciseImageName: apiData.exerciseImageName,
    };

    return new MultimodalExercise(data);
  }

  static deserializeList(apiDataList: IMultimodalExercise[]): MultimodalExercise[] {
    return apiDataList
      ? apiDataList.map(
        (apiData: IMultimodalExercise) => new MultimodalExercise(MultimodalExercise.deserialize(apiData)))
      : [];
  }
}
