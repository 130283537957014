import { IPatientSearch } from '@shared-module/Interfaces';
import { calculateAge, formatPhoneNumber, getValueForDefault, formatAge } from '@utilities';
import { HCPProfile, PatientDailyReportModel, PatientInviteModel, PatientModel } from '.';

export class PatientSearchModel {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  bloodType: string;
  gender: string;
  phoneNumber: string;
  isPatientRecovered: boolean;
  hcpId: number;
  statusId: number;
  hcp: HCPProfile;
  status: string;
  hasPatientProgramStarted: boolean;
  patientDetail: PatientModel;
  patientReport: PatientDailyReportModel[] = [];
  patientInvite: PatientInviteModel;

  constructor(data?: Partial<PatientSearchModel>) {
    Object.assign(this, data);
  }

  get report(): PatientDailyReportModel {
    return this.patientReport?.length && this.patientReport[0];
  }

  get patientCurrentAge(): string {
    if (this.patientReport?.length) {
      return formatAge(this.patientReport[0].currentWalkingAgeInYears, this.patientReport[0].currentWalkingAgeInMonths);
    }
    return '';
  }

  get patientWalkingAge(): string {
    if (this.patientReport?.length) {
      return formatAge(this.patientReport[0].walkingAgeYears, this.patientReport[0].walkingAgeInMonth);
    }
    return '';
  }

  get patientRemainingAge(): string {
    if (this.patientReport?.length) {
      return formatAge(
        this.patientReport[0].remainingWalkingAgeInYears,
        this.patientReport[0].remainingWalkingAgeInMonths,
        true
      );
    }
    return '';
  }

  get patientSingleLegStand(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].singleLegStand, '');
    }

    return getValueForDefault(null, '');
  }

  get patientTimedUpAndGo(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].timedUpAndGo, '');
    }

    return getValueForDefault(null, '');
  }

  get patientBMI(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].bmi, '');
    }

    return getValueForDefault(null, '');
  }

  get patientSixMeterGaitSpeed(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].sixMeterGaitSpeed, '');
    }

    return getValueForDefault(null, '');
  }

  get patientGaitSpeedScore(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].sixMeterGaitSpeed, 'seconds');
    }

    return getValueForDefault(null, '');
  }

  get patientCurrentScore(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].calculateCurrentScore, '');
    }

    return getValueForDefault(null, '');
  }

  get patientTargetScore(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].calculateTargetScore, '');
    }

    return getValueForDefault(null, '');
  }

  get patientNormative(): string {
    if (this.patientReport?.length) {
      return this.patientReport[0]?.normative ? this.patientReport[0].normative : '';
    }
    return '';
  }

  get patientGaitSpeed(): string {
    if (this.patientReport?.length) {
      return getValueForDefault(this.patientReport[0].gaitSpeed, 'm/s');
    }

    return getValueForDefault(null, '');
  }

  get fullName(): string {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : '';
  }

  get patientAge(): number {
    return this.dateOfBirth && calculateAge(new Date(this.dateOfBirth));
  }

  get formattedPhoneNumber(): string {
    return formatPhoneNumber(this.phoneNumber);
  }

  get legStandCurrentLevel(): string {
    if (this.patientReport?.length) {
      return this.patientReport[0].singleLegStandCurrentLevelName;
    }

    return '';
  }

  static deserialize(apiData: IPatientSearch): PatientSearchModel {
    if (!apiData) {
      return new PatientSearchModel();
    }

    const data: Partial<PatientSearchModel> = {
      id: apiData.id,
      email: apiData.email,
      firstName: apiData.firstName,
      lastName: apiData.lastName,
      dateOfBirth: apiData.dateOfBirth,
      bloodType: apiData.bloodType,
      gender: apiData.gender,
      hcpId: apiData.hcpId,
      isPatientRecovered: apiData.isPatientRecovered,
      phoneNumber: apiData.phoneNumber,
      statusId: apiData.statusId,
      status: apiData.status,
      hasPatientProgramStarted: apiData.hasPatientProgramStarted,
      hcp: HCPProfile.deserialize(apiData.hcp),
      patientDetail: PatientModel.deserialize(apiData.patientDetail),
      patientReport: PatientDailyReportModel.deserializeList(apiData.patientReport),
      patientInvite: PatientInviteModel.deserialize(apiData.patientInvite),
    };

    return new PatientSearchModel(data);
  }

  static deserializeList(apiDataList: IPatientSearch[]): PatientSearchModel[] {
    return apiDataList
      ? apiDataList.map((apiData: IPatientSearch) => new PatientSearchModel(PatientSearchModel.deserialize(apiData)))
      : [];
  }
}
