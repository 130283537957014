import { WeeklyProgressModel } from '@shared-module/Models';
import { action, makeAutoObservable } from 'mobx';

export class ProgressStore {
  weeklyProgress: WeeklyProgressModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setWeeklyProgress(data: WeeklyProgressModel[]) {
    this.weeklyProgress = data;
  }
}
