import React, { FC } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useStyles } from './Loader.styles';

const Loader: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress size={65} />
    </Box>
  );
};

export default Loader;
