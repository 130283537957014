import { IMEP } from '@shared-module/Interfaces/MEP.interface';
import { HCPProfile, MultimodalExerciseProgram } from '.';

export class MEPModel {
  id: number;
  name: string;
  isPrivate: boolean;
  multimodalExercisePrograms: MultimodalExerciseProgram[];
  hcp: HCPProfile;

  constructor(data?: Partial<MEPModel>) {
    Object.assign(this, data)
  }

  static deserialize(apiData: IMEP): MEPModel {
    if (!apiData) {
      return new MEPModel();
    }

    const data: Partial<MEPModel> = {
      id: apiData.id,
      name: apiData.name,
      isPrivate: apiData.isPrivate,
      hcp: HCPProfile.deserialize(apiData.hcp),
      multimodalExercisePrograms: MultimodalExerciseProgram.deserializeList(apiData.multimodalExercisePrograms),
    };

    return new MEPModel(data);
  }

  static deserializeList(apiDataList: IMEP[]): MEPModel[] {
    return apiDataList
      ? apiDataList.map((apiData: IMEP) => new MEPModel(MEPModel.deserialize(apiData)))
      : [];
  }
}
