import React, { FC, Suspense /* , useEffect,useState */ } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { observer, Provider } from 'mobx-react';
import { RootStore, UIStore } from '@shared-module/Stores';
import { AuthErrorContainer, Loader, PrivateRoute } from '@shared-module/Components';
import Authorization from './Pages/Authorization/Authorization';
import { useStyles } from './App.styles';
import { routes } from './Routes';
import Unauthorized from './Pages/Unauthorized/Unauthorized';

const theme = createTheme();

const AppWrapper: FC = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Provider {...RootStore}>
        <CssBaseline />
        <div className={classes.mainContainer}>
          <Suspense fallback={true}>
            {UIStore.pageLoading && <Loader />}
            <BrowserRouter basename="/">
              <AuthErrorContainer />
              <Authorization>
                <Routes>
                  {routes.map((m, index) => (
                    <Route
                      key={index}
                      path={`${m.path}/*`}
                      element={
                        <PrivateRoute isPrivate={m.isPrivate} permission={m.permission} element={m.lazyComponent} />
                      }
                    />
                  ))}
                  <Route path="/un-authorized" element={<Unauthorized/>} />
                  <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
              </Authorization>
            </BrowserRouter>
          </Suspense>
        </div>
      </Provider>
    </ThemeProvider>
  );
};

export default observer(AppWrapper);
