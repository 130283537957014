import { TimelineOutlined as TimelineIcon } from '@mui/icons-material';
import { DashboardIcon, SearchIcon, ProgramIcon, CalenderIcon, ActivityIcon, TrackChangesIcon } from '@assets';
import { createTheme } from '@mui/material';
import regex from './Regex';

const theme = createTheme();

export const WalkingAbilityScheme = [
  theme.palette.success.light,
  theme.palette.success.main,
  theme.palette.grey[300],
];

export const LoginFields = [
  {
    name: 'email',
    type: 'string',
    placeholder: 'Enter your Email Address',
    label: 'Email',
    rules: `required|string|regex:${regex.email}`,
    value: '',
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Enter your Password',
    label: 'Password',
    rules: 'required|string|between:8,20',
    value: '',
  },
 
];

export const ResetEmailList = [
  {
    name: 'reset_email',
    type: 'string',
    placeholder: 'Enter your Email Address',
   
    rules: `required|string|regex:${regex.email}`,
    value: '',
  },
];

export const AdminMenuList = [
  {
    to: '/admin/dashboard',
    label: 'Dashboard',
    icon: DashboardIcon,
  },
];

export const HCPMenuList = [
  {
    to: '/hcp/dashboard',
    label: 'Dashboard',
    icon: DashboardIcon,
  },
  {
    to: '/hcp/program',
    label: 'Program',
    icon: ProgramIcon,
  },
  {
    to: '/hcp/customPrograms',
    label: 'Custom Programs',
    icon: CalenderIcon,
  },
  {
    to: '/hcp/patient/search',
    label: 'Search',
    icon: SearchIcon,
  },
];

export const PatientMenuList = [
  {
    to: '/patient/dashboard',
    label: 'Dashboard',
    icon: DashboardIcon,
  },
  {
    to: '/patient/progress',
    label: 'My Progress',
    icon: TimelineIcon,
  },
  // {
  //   to: '/',
  //   label: 'Program',
  //   icon: ProgramIcon,
  // },
  // {
  //   to: '/',
  //   label: 'Calender',
  //   icon: CalenderIcon,
  // },
  {
    to: '/patient/activity',
    label: 'My Exercise Program',
    icon: ActivityIcon,
  },
  {
    to: '/patient/excercise-tracker',
    label: 'Activity Record',
    icon: TrackChangesIcon,
  },
  // {
  //   to: '/',
  //   label: 'Search',
  //   icon: SearchIcon,
  // },
];

export const TestingDayFields = [
  {
    name: 'singleLegStand',
    label: 'Time Standing One Leg',
    value: '',
    type: 'number',
    rules: 'required',
  },
  {
    name: 'gaitSpeed',
    label: '6 Meter Gait Speed',
    value: '',
    type: 'number',
    rules: 'required',
  },
  {
    name: 'timedUpAndGo',
    label: 'Timed Up and Go',
    value: '',
    type: 'number',
    rules: 'required',
  },
  {
    name: 'weight',
    label: 'Weight in Kg',
    value: '',
    type: 'number',
    rules: 'required|numeric|min:20|max:120',
  },
];

export const TestReportFields = [
  {
    name: 'singleLegStand',
    label: 'Time Standing One Leg',
    value: '',
    type: 'number',
    rules: 'required',
  },
  {
    name: 'gaitSpeed',
    label: '6 Meter Gait Speed',
    value: '',
    type: 'number',
    rules: 'required',
  },
  {
    name: 'timedUpAndGo',
    label: 'Timed Up and Go',
    value: '',
    type: 'number',
    rules: 'required',
  },
  {
    name: 'weight',
    label: 'Weight in Kg',
    value: '',
    type: 'number',
    rules: 'required|numeric|min:20|max:120',
  },
  {
    name: 'height',
    label: 'Height in cm',
    value: '',
    type: 'number',
    rules: 'required|numeric|min:50|max:220',
  },
];

export const RegistrationFields = [
  {
    name: 'firstName',
    type: 'string',
    placeholder: 'Enter your First Name',
    label: 'First Name',
    rules: `required|string|regex:${regex.name}`,
    value: '',
  },
  {
    name: 'lastName',
    type: 'string',
    placeholder: 'Enter your Last Name',
    label: 'Last Name',
    rules: `required|string|regex:${regex.name}`,
    value: '',
  },
  {
    name: 'email',
    type: 'string',
    placeholder: 'Enter your Email Address',
    label: 'Email',
    rules: `required|string|regex:${regex.email}`,
    value: '',
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Enter your Password',
    label: 'Password',
    rules: 'required|string|between:8,20',
    value: '',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    placeholder: 'Enter your confirm password',
    label: 'Confirm Password',
    rules: 'required|string|same:password',
    value: '',
  },
  {
    name: 'phoneNumber',
    type: 'number',
    placeholder: 'Enter your Mobile Number',
    label: 'Mobile Number',
    rules: 'required|string|min:10|max:10',
    value: '',
  },
  {
    name: 'hcpName',
    type: 'text',
    placeholder: 'HCP Name',
    label: 'HCP Name',
  },
];

export const InvitePatientFields = [
  {
    name: 'firstName',
    type: 'string',
    placeholder: 'Enter your First Name',
    label: 'First Name',
    rules: `required|string|regex:${regex.name}`,
    value: '',
  },
  {
    name: 'lastName',
    type: 'string',
    placeholder: 'Enter your Last Name',
    label: 'Last Name',
    rules: `required|string|regex:${regex.name}`,
    value: '',
  },
  {
    name: 'email',
    type: 'string',
    placeholder: 'Enter your Email Address',
    label: 'Email',
    rules: `required|string|regex:${regex.email}`,
    value: '',
  },
  {
    name: 'hcpName',
    type: 'text',
    placeholder: 'HCP Name',
    label: 'HCP Name',
  },
];

export const PatientRegisterFields = [
  {
    name: 'email',
    type: 'string',
    placeholder: 'Enter your Email Address',
    label: 'Email',
    rules: `required|string|regex:${regex.email}`,
    value: '',
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Enter your Password',
    label: 'Password',
    rules: 'required|string|between:8,20',
    value: '',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    placeholder: 'Enter your confirm password',
    label: 'Confirm Password',
    rules: 'required|string|same:password',
    value: '',
  },
  {
    name: 'hcpName',
    type: 'text',
    placeholder: 'HCP Name',
    label: 'HCP Name',
  },
];

export const ConfirmationFields = [
  {
    name: 'email',
    type: 'string',
    placeholder: 'Enter your Email Address',
    label: 'Email',
    rules: `required|string|regex:${regex.email}`,
    value: '',
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Enter your Password',
    label: 'Password',
    rules: 'required|string|between:8,20',
    value: '',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    placeholder: 'Enter your confirm password',
    label: 'Confirm Password',
    rules: 'required|string|same:password',
    value: '',
  },
  
];

export const HCPRegisterFields = [
  {
    name: 'title',
    type: 'string',
    placeholder: 'Select Title',
    label: 'Title',
    rules: 'required|string',
    value: '',
  },
  {
    name: 'firstName',
    type: 'string',
    placeholder: 'Enter your First Name',
    label: 'First Name',
    rules: `required|string|regex:${regex.name}`,
    value: '',
    /* |regex:${regex.name} */
  },
  {
    name: 'lastName',
    type: 'string',
    placeholder: 'Enter your Last Name',
    label: 'Last Name',
    rules: `required|string|regex:${regex.name}`,
    value: '',
    /* |regex:${regex.name} */
  },
  {
    name: 'email',
    type: 'string',
    placeholder: 'Enter your Email Address',
    label: 'Email',
    rules: `required|string|regex:${regex.email}`,
    value: '',
    /* |regex:${regex.email} */
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Enter your Password',
    label: 'Password',
    rules: 'required|string|between:8,20',
    value: '',
  },
  {
    name: 'confirmPassword',
    type: 'password',
    placeholder: 'Enter your confirm password',
    label: 'Confirm Password',
    rules: 'required|string|same:password',
    value: '',
  },
  {
    name: 'specialist',
    type: 'string',
    placeholder: 'Enter your Specialization',
    label: 'Specialist',
    rules: 'required|string',
    value: '',
  },
];

export const walkingAgeAboutFields = [
  {
    name: 'weight',
    value: '',
    type: 'number',
    rules: 'required|numeric|min:20|max:120',
  },
  {
    name: 'height',
    value: '',
    type: 'number',
    rules: 'required|numeric|min:50|max:220',
  },
];

export const HealthConditionAboutFields = [
  {
    name: 'weight',
    value: '',
    type: 'number',
    rules: 'required|numeric|min:20|max:120',
  },
  {
    name: 'height',
    value: '',
    type: 'number',
    rules: 'required|numeric|min:50|max:220',
  },
  {
    name: 'isDiagnosed',
    value: false,
    type: 'boolean',
    rules: 'required|boolean',
  },
];

export const walkingSpeedFields = [
  {
    name: 'speed',
    /*  label: 'Please enter the speed: ', */
    value: '',
    rules: 'required',
    type: 'number',
  },
];

export const TitleList = [ 'Dr.', 'Mr.', 'Ms.' ];

