import { Button, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './Unauthorized.styles';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Un_Auth = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h5" className={classes.mainHeading}>
        {t('Unauthorized')} !
      </Typography>
      <Typography variant="h5" className={classes.mainHeading}>
        {t('You dont have the permissions to access this page')}.
      </Typography>
      <Button variant="contained" className={classes.goback}  size="small" onClick={() => navigate(-2)}>
        {t('Go Back')}
      </Button>
    </div>
  );
};

export default Un_Auth;