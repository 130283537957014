import { IUserResponse } from '@shared-module/Interfaces';
import { UserRegisterRequestModel, UserResponse } from '@shared-module/Models';
import { url } from '@shared-module/Services/ApiEndpoints';
import { HttpClient } from '@shared-module/Tools';
import { action, makeObservable, observable } from 'mobx';
import { Observable, of, tap, map, switchMap } from 'rxjs';



class AuthStore {
  @observable public isLoggedIn: boolean | null = null;
  @observable public appUser: UserResponse = null;
  @observable public language: string = '';

  constructor() {
    makeObservable(this);
  }

  public get userId(): number {
    return this.appUser?.loginUserId || null
  }

  public getUserProfile(): Observable<UserResponse> {
    const user: any = JSON.parse(localStorage.getItem('user') || null);
    return of(user).pipe(tap(_user => {
      if (_user) {
        this.appUser = UserResponse.deserialize(_user)
        this.isLoggedIn = true;
      }
    }));
  }

  @action
  public logOut(): void {
    this.appUser = null;
    this.isLoggedIn = false;
  }
  @action
  public setLanguage(language:string){
    this.language=language
  }

  public userLogin(email: string, password: string): Observable<UserResponse> {
    const http: HttpClient = new HttpClient();
    const req = {
      email,
      password,
    };
    return http.post<IUserResponse>(url.login, req)
      .pipe(
        map((response: IUserResponse) => {
          localStorage.setItem('Usertoken', response.userToken);
          localStorage.setItem('user', JSON.stringify(UserResponse.deserialize(response)));
          return UserResponse.deserialize(response);
        }),
        tap((user) => {
          this.isLoggedIn = true;
          this.appUser = user;
        }),
        switchMap(() => this.getUserProfile())
      )
  }

  public registerUser(req: UserRegisterRequestModel): Observable<boolean> {
    const http: HttpClient = new HttpClient();
    return http.post<boolean>(url.register, req);
  }
}

export default new AuthStore();
